.mx_EventTile {
    .mx_EventTile_edited.ctalk_EventTile_edited {
        cursor: initial;
    }

    &.ctalk_EventTile_highlight {
        &[data-layout="group"] .mx_EventTile_line,
        &[data-layout="bubble"]::before {
            animation-name: highlight-message-replied;
            animation-timing-function: cubic-bezier(1, 0, 1, 0);;
            animation-duration: 2s;
            z-index: 0;
        }
    }

    .ctalk_Forward_line_Image {
        .ctalk_MessageEvent_forward {
            padding: 10px;
            /*noinspection CssUnresolvedCustomProperty*/
            background: var(--backgroundColor);

            & + .mx_MImageBody {
                .ctalk_MImageBody_Preview {
                    /*noinspection CssUnresolvedCustomProperty*/
                    background: var(--backgroundColor);
                }
            }
        }
    }

    .ctalk_Forward_line_VideoBody {
        // max-width: 100% !important;

        .ctalk_MessageEvent_forward {
            padding: 10px;
            /*noinspection CssUnresolvedCustomProperty*/
            background: var(--backgroundColor);
        }
    }

    .ctalk_Forward_line_sticker {
        /*noinspection CssUnresolvedCustomProperty*/
        background: var(--backgroundColor);
        /*noinspection CssUnresolvedCustomProperty*/
        padding: calc(var(--gutterSize) - 1px);

        .ctalk_MessageEvent_forward {
            padding-bottom: 5px;
        }
    }

    &[data-layout="bubble"]:not(.mx_EventTile_info) .ctalk_EventTile_messages {
        &:not(.mx_EventTile_noBubble) {
            &.mx_EventTile_line:not(.mx_EventTile_mediaLine, .ctalk_EventTile_location) {
                /*noinspection CssUnresolvedCustomProperty*/
                padding-right: calc(var(--gutterSize) - 1px);
                padding-bottom: 25px;
            }
        }
    }

    .ctalk_EventTile_messages {
        .ctalk_EventTile_message_forward .mx_MImageBody {
            .ctalk_MImageBody_thumbnail_container,
            .mx_MImageBody_thumbnail_container {
                border-top-left-radius: unset;
                border-top-right-radius: unset;
            }
        }

        .mx_MImageBody {
            .ctalk_MImageBody_thumbnail {
                display: inline-block;
                flex-wrap: wrap;
                justify-content: flex-start;
                position: relative;
                width: 100%;
            }
        }
    }

    .mx_RedactedBody {
        min-width: 200px;
    }

    .ctalk_EventTile_Reply_Content:has(.mx_RedactedBody) {
        min-width: 200px;
    }

    .mx_EventTile_image {
        .mx_RedactedBody {
            min-height: 40px;
        }

        .ctalk_EventTile_Reply_Content:has(.mx_RedactedBody) {
            min-height: 40px;
        }
    }

    .ctalk_EventTile_message_forward:has(.mx_RedactedBody), .ctalk_Forward_line_Image:has(.mx_RedactedBody) {
        min-height: 65px;
    }
}

.mx_RoomView_timeline {
    .mx_RoomView_messageListWrapper {
        .mx_RoomView_MessageList {
            .mx_EventTile {
                .ctalk_EventTile_Media_Description {
                    .mx_MessageTimestamp {
                        color: var(--MessageTimestamp-color);
                        border-radius: unset;
                        background-color: unset;
                        padding: unset;
                    }
                }
            }
        }
    }
}

/* Improve UI message media in layout "bubble" */
.mx_EventTile[data-layout="bubble"] {
    .mx_MTextBody {
        min-width: 80px;
    }


    .ctalk_Forward_line_VideoBody {
        .ctalk_MVideoBody {
            &,
            .ctalk_MVideoBody_container {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    &:has(.ctalk_EventTile_location) {
        &:not(.mx_EventTile_noBubble) .mx_EventTile_line:not(.mx_EventTile_mediaLine) {
            padding: unset;
            padding-right: unset;
        }

        .ctalk_MessageEvent_forward {
            padding: 10px;
        }
    }

    .mx_EventTile_line {
        /* Improve UI message reply */

        &.ctalk_EventTile_reply {
            &.mx_EventTile_mediaLine {
                background-color: var(--backgroundColor);

                .mx_ReplyChain_wrapper {
                    padding-top: 10px;
                    padding-left: 10px;
                    max-width: calc(100% - 20px);
                }

                &:has(.ctalk_EventTile_Reply_Content .mx_MImageBody, .ctalk_EventTile_Reply_Content .mx_MVideoBody) {
                    max-width: 320px;
                }

                .ctalk_EventTile_Reply_Icon {
                    display: none;
                }

                .mx_MImageBody {
                    .mx_MImageBody_thumbnail_container {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        min-width: 320px;
                        max-width: 320px;

                        .ctalk_MImageBody_Preview {
                            .ctalk_MImageBody_Background {
                                min-width: 320px;
                            }
                        }
                    }
                }

                .ctalk_MVideoBody {
                    &:has(.ctalk_MVideoBody_error) {
                        background: unset;
                    }
                }

                .mx_MVideoBody {
                    .mx_MVideoBody_container {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;

                        &:not(.mx_ReplyTile_video) {
                            min-width: 320px;
                        }
                    }
                }

                .mx_ReplyTile_video {
                    .mx_MVideoBody {
                        .mx_MVideoBody_container {
                            min-width: unset;
                        }
                    }
                }
            }
        }
    }

    &[data-self="true"] {
        .ctalk_Forward_line_Image {
            .ctalk_MessageEvent_forward {
                border-top-left-radius: var(--cornerRadius);
            }
        }

        .ctalk_Forward_line_VideoBody {
            .ctalk_MessageEvent_forward {
                border-top-left-radius: var(--cornerRadius);
            }

            .ctalk_MVideoBody {
                .ctalk_MVideoBody_no_description {
                    border-top-left-radius: 0;
                    height: 92%;
                }
            }

            .ctalk_MVideoBody_no_description {
                border-top-right-radius: 0;
            }
        }

        .mx_EventTile_line .mx_MVideoBody .mx_MVideoBody_container {
            &:not(.ctalk_MVideoBody_no_description) {
                border-bottom-left-radius: 0 !important; // Important due to css in library also important
            }
        }

        &:has(.ctalk_EventTile_location) {
            &:not(.mx_EventTile_noBubble) .mx_EventTile_line:not(.mx_EventTile_mediaLine) {
                border-bottom-right-radius: var(--cornerRadius);
            }
        }
    }

    &[data-self="false"] {
        .ctalk_Forward_line_Image {
            .ctalk_MessageEvent_forward {
                border-top-right-radius: var(--cornerRadius);
            }
        }

        .ctalk_Forward_line_VideoBody {
            .ctalk_MessageEvent_forward {
                border-top-right-radius: var(--cornerRadius);
            }

            .ctalk_MVideoBody {
                .ctalk_MVideoBody_no_description {
                    border-top-right-radius: 0;
                    height: 92%;
                }
            }

            .ctalk_MVideoBody_no_description {
                border-top-left-radius: 0;
            }
        }

        .ctalk_Forward_line_Image_Modern {
            .ctalk_MImageBody_Preview {
                border-radius: 0 0 12px 12px;
            }
        }

        .mx_EventTile_line .mx_MVideoBody .mx_MVideoBody_container {
            &:not(.ctalk_MVideoBody_no_description) {
                border-bottom-right-radius: 0 !important; // Important due to css in library also important
            }
        }

        &:has(.ctalk_EventTile_location) {
            &:not(.mx_EventTile_noBubble) .mx_EventTile_line:not(.mx_EventTile_mediaLine) {
                border-bottom-left-radius: var(--cornerRadius);
            }
        }
    }

    &.mx_EventTile_lastInSection[data-self="true"] {
        .mx_EventTile_line .mx_MVideoBody .mx_MVideoBody_container {
            &:not(.ctalk_MVideoBody_no_description) {
                border-bottom-right-radius: 0;
            }
        }
    }

    &.mx_EventTile_lastInSection[data-self="false"] {
        .mx_EventTile_line .mx_MVideoBody .mx_MVideoBody_container {
            &:not(.ctalk_MVideoBody_no_description) {
                border-bottom-left-radius: 0;
            }
        }
    }
}

.mx_RoomView_messageListWrapper .mx_EventTile {
    .ctalk_Forward_line_VideoBody_Modern {
        .ctalk_MVideoBody_container {
            /*noinspection CssUnresolvedCustomProperty*/
            border-bottom-right-radius: var(--cornerRadius) !important;
        }
    }

    .ctalk_Not_Forward_line_VideoBody_Modern {
        span.mx_MVideoBody {
            .mx_MVideoBody_container {
                video {
                    border-radius: 12px;
                }
            }
        }
    }

    .ctalk_Forward_line_VideoBody_Modern {
        .mx_MVideoBody .mx_MVideoBody_container {
            border-bottom-left-radius: 12px;

            video {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }
}

.mx_EventTile[data-layout="bubble"][data-self="false"] {
    margin-top: 12px;
}

/* Improve UI message in layout "bubble" */
.mx_EventTile[data-layout="bubble"] {
    .ctalk_MImageBody_Error {
        .ctalk_MImageBody_description {
            margin-bottom: 5px;
        }

        .ctalk_EventTile_content {
            padding: 0 10px 10px;
            margin-bottom: 15px;
        }
    }

    .ck_media_description {
        .mx_MTextBody {
            padding: 0 10px;
            margin: 15px 0;
        }

        .ctalk_EventTile_content {
            padding: 0 10px 10px;
        }
    }

    .ctalk_MImageBody_thumbnail {
        /*noinspection CssUnresolvedCustomProperty*/
        background: var(--backgroundColor);

        .ctalk_MImageBody_description {
            padding: 0 10px 7px 10px;
        }
    }

    .ctalk_MImageBody_Error {
        .ctalk_MImageBody_description {
            padding-bottom: 5px;
        }
    }

    .ctalk_MVideoBody_error {
        padding-bottom: 0;
        margin-bottom: 5px;
    }

    .ctalk_MVideoBody_description {
        margin-top: 5px;
    }

    .mx_EventTile_msgOption {
        .CTalk_ReadReceiptGroup_Has_Pinned {
            margin-right: 1.2rem;
        }
    }

    .ctalk_EventTile_messages {
        &.mx_EventTile_line {
            max-width: 480px;

            > a {
                .ck_Group_MessageTimestamp {
                    color: #acacac;
                    font-size: .625rem;
                    display: inline-flex;
                    align-items: center;
                    gap: 3px;
                }
            }

            &:has(.mx_EventTile_e2eIcon) {
                > a,
                .mx_MessageTimestamp {
                    display: flex;
                    align-items: center;
                }

                .mx_EventTile_e2eIcon {
                    margin-right: 3px;
                    width: 10px;
                }
            }

            &:has(.mx_AccessibleButton) {
                > a,
                .mx_MessageTimestamp {
                    display: flex;
                    align-items: center;
                }

                .mx_EventTile_e2eIcon {
                    margin-right: 3px;
                    width: 10px;
                }
            }

            &.mx_EventTile_mediaLine {
                &.mx_EventTile_sticker {
                    &:not(.ctalk_Forward_line_sticker) {
                        .mx_MessageTimestamp {
                            /* Hardcoded colours because it's the same on all themes */
                            background-color: rgb(0 0 0 / 38%);
                            color: #ffffff;
                            padding: 0 4px 0 4px;
                        }
                    }
                }

                &.mx_EventTile_image:not(.ck_EventTile_media_deleted) {
                    &:not(.ctalk_EventTile_Media_Description) {
                        .mx_MessageTimestamp {
                            border-radius: unset;
                            background-color: unset;
                            padding: unset;
                        }

                        .ck_Group_MessageTimestamp {
                            /*noinspection CssUnresolvedCustomProperty*/
                            border-radius: var(--MBody-border-radius);
                            background-color: rgba(0, 0, 0, 0.6);
                            color: #ffffff;
                            padding: 0 4px 0 4px;
                        }
                    }
                }
            }

            &.mx_EventTile_sticker {
                > a,
                .mx_MessageActionBar + .mx_MessageTimestamp {
                    left: unset;
                    right: 0;
                }
            }

        }
    }
}

.mx_EventTile[data-layout="bubble"].mx_EventTile_bad {
    .ctalk_EventTile_messages .mx_ReplyChain_wrapper .mx_ReplyTile .mx_DecryptionFailureBody {
        grid-area: message;
    }
}

@keyframes highlight-message-description-replied {
    0% {
        background-color: rgb(255, 255, 204, 0.7);
    }
    100% {
        background-color: unset;
    }
}

@keyframes highlight-message-replied {
    0% {
        background-color: rgb(255, 255, 204, 0.7);
    }
    70% {
        background-color: rgb(255, 255, 204, 0.7);
    }
    100% {
        background-color: rgb(255, 255, 204, 0);
    }
}

.mx_EventTile[data-layout="group"] .mx_MessageTimestamp {
    left: 3px !important;
}

.ctalk_MVideoBody {
    /*noinspection CssUnresolvedCustomProperty*/
    background: var(--backgroundColor);
    /*noinspection CssUnresolvedCustomProperty*/
    border-radius: var(--cornerRadius);
    max-width: 100%;
    min-height: calc(100% - 40px);
}

.mx_EventTile[data-layout="bubble"].mx_EventTile_continuation {
    margin-top: 4px;
}

.mx_EventTile[data-layout="bubble"] .mx_EventTile_avatar {
    top: 0;
}

.mx_EventTile[data-layout="bubble"] .ctalk_EventTile_avatar {
    cursor: default;
    border: unset;
    padding: 4px;
}

.ctalk_EventTile_messages {
    .ctalk_EventTile_Reply_Messages {
        display: flex;

        .ctalk_EventTile_Reply_Icon {
            padding-right: 5px;
        }

        // this hot fix for reply a image with description by another video in large mode will hide selection
        // TODO summary size of video, image in small/large mode then recalculate for modern/bubble mode

        & > div {
            max-width: 100%;
        }

        .ctalk_EventTile_Reply_Content {
            width: 100%;
        }
    }

    .ctalk_EventTile_Reply_Messages_Video {
        .ctalk_EventTile_Reply_Content {
            width: unset;
        }
    }

    .mx_ReplyChain_wrapper {
        width: 100%;
    }
}

.ctalk_cform_timestamp {
    cursor: unset;
}

.mx_EventTile[data-layout="bubble"][data-self="true"] {
    .ctalk_EventTile_Reply_Messages_Self {
        justify-content: flex-end;

        .ctalk_EventTile_Reply_Content {
            width: unset;
        }
    }
}

.ctalk_EventTile_e2eIcon {
    z-index: 2;
}

.mx_EventTile[data-layout="bubble"] .ctalk_DisambiguatedProfile {
    max-width: 100%;
}

.mx_EventTile[data-layout="bubble"][data-self="true"] .mx_EventTile_sticker {
    margin-right: 0;

    a {
        right: 0;
        left: unset;
    }
}

.mx_EventTile[data-layout="bubble"] {
    &[data-self="true"] {
        .mx_MediaBody {
            /*noinspection CssUnresolvedCustomProperty*/
            padding-right: calc(var(--gutterSize) - 1px);
            //padding-bottom: 25px;
        }

        .ctalk_EventTile_message_forward {
            .mx_MStickerBody_wrapper {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    &:has(.ctalk_EventTile_message_forward) {
        .mx_EventTile_line.mx_EventTile_sticker .mx_MStickerBody_wrapper {
            padding: 12px 0px;
        }
    }
}

.mx_EventTile[data-layout="bubble"] {
    &:hover {
        &::before {
            background-color: unset;
        }
    }
}

.mx_EventTile[data-layout="group"] {
    &:hover {
        &::before, .mx_EventTile_line {
            background-color: unset;
        }
    }
}

.mx_EventTile:hover {
    .ctalk_HiddenImagePlaceholder_Hover_Dark {
        background-color: var(--cpd-color-bg-subtle-secondary);
    }

    .ctalk_HiddenImagePlaceholder_Hover_Light {
        background-color: var(--cpd-color-bg-subtle-primary);
    }
}

blockquote.mx_ReplyChain {
    background-color: var(--user-bg-color);
    padding-right: 15px;
    border-radius: 5px;
    border-left-width: 3px;

    .mx_ReplyTile > a {
        color: var(--cpd-color-text-primary);
    }
}

.mx_EventTile[data-layout="group"].mx_EventTile_selected .mx_EventTile_line {
    background-color: unset;
}

.mx_EventTile[data-layout="bubble"].mx_EventTile_selected::before {
    background-color: unset;
}

// Increase line spacing between mentions
.mx_EventTile[data-layout="bubble"]:not(.mx_EventTile_noBubble) .mx_EventTile_content {
    line-height: 1.25rem;
}

.ctalk_EventTile_content .markdown-body ul,
.ctalk_EventTile_content .markdown-body ol {
    list-style-position: inside;
    padding: 0;

    li {
        text-indent: 2em;
        padding-left: 0;

        span {
            text-indent: 0;
        }
    }
}
