.ctalk_common_rooms_card {
    align-items: center;
    .mx_BaseCard_header {
        width: 100%;
    }
    .mx_RoomTile_titleContainer {
        padding-left: 10px;
    }
    .ctalk_common_rooms_search {
        background-color: var(--cpd-color-theme-bg);
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 1;
        padding: 1px 0;
    }
    .ctalk_search_input {
        //margin-top: 0;
    }
}
