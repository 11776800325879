.ctalk_Confirmation_Form {
    &.mx_Dialog_fixedWidth {
        width: 600px;
    }
    .ctalk_Form_Info_Action {
        button:not(.mx_Dialog_nonDialogButton):not([class|="maplibregl"]):not(.mx_AccessibleButton) {
            display: flex;
            background: var(--cpd-color-gray-400);
            align-items: center;
            justify-content: space-between;
            border: none;
            border-radius: .8em;
            padding: 0 1em;
            margin: .5em 0;
            cursor: pointer;
            font-weight: unset;
            .ctalk_Form_Info_Download_File_Name {
                color: initial;
            }
        }
    }
    .ctalk_Form_radio {
        padding-top: unset;
        .ctalk_Form_radio_Items {
            padding: .1em 0;
            display: flex;
            align-items: center
        }
        .ctalk_Form_radio_Items_Checked {
            .mx_StyledRadioButton_content {
                color: var(--cpd-color-green-ctalk);
            }
        }
    }

    .ctalk_Form_file {
        background: var(--cpd-color-green-300);
        padding: 1em;
        border-radius: .8em;
        margin-top: 12px;
        .ctalk_Form_file_text {
            color: var(--cpd-color-green-ctalk);
        }
        .ctalk_Form_file_text_preview_expired {
            color: var(--MessageTimestamp-color);
        }
        .ctalk_Form_file_accept_info {
            font-size: $font-13px;
            padding: .2em 0;
            color: var(--MessageTimestamp-color);
        }
        .ctalk_Form_file_line {
            border-bottom: 1px solid var(--cpd-color-gray-400);
            padding: .5em 0;
        }
        .ctalk_Form_file_action {
            padding: .5em 0;
            display: flex;
            justify-content: center;
            gap: .5em;
            .ctalk_AccessibleButton {
                flex-grow: 1;
            }
            .ctalk_AccessibleButton:nth-child(1):only-child {
                width: 100%;
            }
            .text-error {
                width: 100%;
                justify-content: left;
            }
            .ctalk_File_Selected_Button {
                background-color: transparent;
                border: 1px solid var(--cpd-color-text-action-accent);
                color: var(--cpd-color-text-action-accent);
                -webkit-box-align: center;
                -webkit-box-pack: center;
                align-items: center;
                border-radius: 8px;
                display: inline-flex;
                font: var(--cpd-font-body-md-regular);
                justify-content: center;
                padding: 7px 18px;
                text-align: center;
                word-break: keep-all;
            }
            .ctalk_File_Selected_Button:disabled {
                opacity: .4;
                cursor: no-drop;
            }
        }
        .ctalk_file_selected {
            padding: 1em 0;
            .ctalk_file_selected_info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                .mx_MFileBody_info_filename {
                    color: var(--cpd-color-green-ctalk);
                }
            }
            .mx_Field {
                margin: unset;
            }
            .ctalk_response_file_note {
                padding: .1em 0;
                white-space: pre-line;
            }
        }
    }

    .ctalk_Form_textarea {
        .ctalk_textarea_input_count {
            font-size: $font-13px;
        }
        .ctalk_textarea_item {
            margin-top: .5em;
            padding: .5em;
            width: calc(100% - 1em);
            font-family: inherit;
            font-size: inherit;
            border-radius: .8em;
            resize: none;
        }
    }
    .ctalk_form_submit_checkbox {
        line-height: 1em;
        padding-top: 1.5em;
    }
}

.ctalk_Form_Info_Action {
    padding-bottom: 5px;
    .ctalk_file_info_preview {
        width: unset;
    }
}

.ctalk_input_count {
    font-size: $font-13px;
    text-align: right;
    color: var(--MessageTimestamp-color);
}

.ctalk_file_info_preview_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ctalk_file_info_preview_button {
        padding-left: 40px;
    }
}

.ctalk_file_info_preview {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: calc(100% - 1px - 50px);
    .ctalk_file_info_preview_thumn {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-right: 10px;
        display: flex;
        vertical-align: top;
        align-items: center;
        .mx_UploadConfirmDialog_imagePreview {
            max-height: 50px;
        }
        .mx_MFileBody_info {
            .image_icon {
                fill: #656d77;
                padding-top: 6px;
            }
        }
    }
    .ctalk_UploadFile_Info {
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        .mx_MFileBody_info_filename {
            width: calc(100% - 1px - 1px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .ctalk_file_info_preview_button {
        padding-left: 20px;
    }
}

.ctalk_file_info_preview_restricted {
    cursor: default;
}

.ctalk_MFileBody_info:has(.ctalk_file_info_preview_restricted) {
    cursor: default;
}

.ctalk_Audio_preview {
    width: 100%;
    .mx_MediaBody {
        background-color: unset;
        max-width: unset;
    }
}

.ctalk_Audio_preview_thumn {
    &.ctalk_file_info_preview_thumn,
    .ctalk_file_info_preview_thumn {
        width: 100%;
        // overflow: hidden;
        white-space: nowrap;
        border-radius: unset;
        padding-top: 10px;
    }
}

.ctalk_preview_download {
    position: relative;
    .ctalk_preview_download_button {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        &_download {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: hsla(215, 96%, 10%, 0.35);
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
            &::after {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                background-color: #ffffff;
                mask-size: 20px;
                mask-repeat: no-repeat;
                mask-image: url("~@vector-im/compound-design-tokens/icons/download.svg");
            }
        }
    }
}

.required:after {
    content:" *";
    color: red;
}
