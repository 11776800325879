.mx_RoomList.ck_RoomList {
    padding-right: unset;

    .mx_RoomSublist {
        margin-left: unset;

        .mx_RoomSublist_headerContainer {
            margin-left: 8px;
        }

        &.mx_RoomSublist_minimized {
            .mx_RoomSublist_skeletonUI {
                text-align: center;
            }
        }
    }
}

.mx_RoomSublist_skeletonUI {
    margin-left: 12px;
}

.mx_RoomListHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mx_RoomListHeader_contextMenuButton {
        width: 32px;
        height: 32px;
        border-radius: 8px;
        position: relative;
        padding: 8px;
        margin-left: 8px;
        margin-right: 12px;
        background-color: unset;
        box-sizing: border-box;
        flex-shrink: 0;

        &::before {
            content: "";
            width: 32px;
            height: 32px;
            position: absolute;
            top: 0;
            left: 0;
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            background-color: var(--cpd-color-green-ctalk);
            mask-image: url("$(web_res)/themes/element/icons/sort.svg");
        }

        &:hover {
            background-color: $panel-actions;

            &::before {
                background-color: var(--cpd-color-green-ctalk);
            }
        }

        &[aria-expanded="true"] {
            background-color: unset;

            &::before {
                transform: unset;
            }
        }
    }

    .mx_RoomListHeader_plusButton {
        width: 32px;
        height: 32px;
        border-radius: 8px;
        position: relative;
        padding: 8px;
        margin-left: 8px;
        margin-right: 12px;
        background-color: unset;
        box-sizing: border-box;
        flex-shrink: 0;

        &::before {
            content: "";
            width: 32px;
            height: 32px;
            position: absolute;
            top: 0;
            left: 0;
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            background-color: var(--cpd-color-green-ctalk);
            mask-image: url("$(web_res)/themes/element/icons/chat_alt_add.svg");
        }

        &:hover {
            background-color: $panel-actions;

            &::before {
                background-color: var(--cpd-color-green-ctalk);
            }
        }
    }

    .ck_RoomListHeader_tile {
        font: var(--cpd-font-body-md-regular);
        font-weight: var(--cpd-font-weight-medium);
    }

    &.ck_RoomListHeader_minimized {
        justify-content: center;

        .mx_RoomListHeader_contextMenuButton {
            display: none;
        }

        .ck_RoomListHeader_tile {
            display: none;
        }
    }
}

.mx_RoomListHeader_Safari {
    .mx_RoomListHeader_contextMenuButton {
        border-radius: unset;
        clip-path: inset(0 round 8px);
    }
}

.mx_RoomSublist_contextMenu.ck_RoomListHeader_contextMenu_options {
    hr {
        margin-bottom: unset;
    }
}

.mx_LeftPanel_outerWrapper {
    border-right: 1px solid var(--cpd-color-alpha-gray-400);
}
