.mx_EventTileBubble.mx_MJitsiDirect {
    display: flex;
    align-items: flex-end;
    &::before {
        padding-right: 1rem
    }
    &.mx_MJitsiVoiceCall {
        &::before {
            -webkit-mask-image: url('~matrix-react-sdk/res/img/element-icons/call/voice-call.svg');
            mask-image: url('~matrix-react-sdk/res/img/element-icons/call/voice-call.svg');
        }
    }
}
