.mx_RoomSummaryCard {
    ._input_ik1u1_32:checked + ._ui_ik1u1_42 {
        background: var(--cpd-color-green-ctalk);
        border-color: var(--cpd-color-green-ctalk);
    }
    ._ui_ik1u1_42 {
        background: $togglesw-off-color;
        border-color: $togglesw-off-color;
        &::after {
            background: $call-primary-content;
        }
    }

    .ctalk_common_rooms {
        svg {
            fill: var(--cpd-color-icon-primary);
        }
    }
}
