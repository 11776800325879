.mx_AuthPage_modalContent {
    .ctalk_SetupGoogleAuthenticator {
        background: #ffffff;
    }
}
.mx_AuthBody {
    .mx_SSOButtons {
        margin-top: 15px;
    }
}
.mx_AccessibleButton {
    &.mx_AccessibleButton_hasKind {
        &.ctalk_QRLogin_btn {
            font-size: $font-15px;
            width: 100%;
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}
