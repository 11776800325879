.Pdf_view input,
.Pdf_view button {
    font: inherit;
}

.Pdf_view header {
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: white;
}

.Pdf_view header h1 {
    font-size: inherit;
    margin: 0;
}

.Pdf_view__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
}

.Pdf_view__container__load {
    margin-top: 1em;
    color: white;
}

.Pdf_view__container__document {
    overflow-y: auto;
    height: calc(100vh - 50px);
    max-width: calc(100% - 2em);
    margin: 1em 0;
}

.Pdf_view__container__document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Pdf_view__container__document .react-pdf__Page {
    margin: 1em 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.Pdf_view__container__document .react-pdf__message {
    padding: 20px;
    color: white;
}