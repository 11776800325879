.ctalk_MultiFactor_refresh {
    &.mx_AccessibleButton {
        &.mx_AccessibleButton_kind_link_inline {
            margin-left: 10px;
            font-size: $font-15px;
        }
    }
    &Icon {
        width: 16px;
        height: 15px;
        margin-right: 5px;
    }
}

.ctalk_MultiFactorAuthenticationPanel {
    &_description {
        margin-bottom: 15px;
    }
    &_item {
        padding: 0 8px;
        display: flex;
        &_status {
            width: 16px;
            margin-right: 5px;
            span {
                margin: 2px 5px 0 0 !important;
            }
        }
        &_action {
            margin-left: auto;
            .ctalk_MultiFactorPanel_button {
                min-width: 50px;
            }
        }

        &_metadata {
            margin-top: 4px;
            font-size: $font-12px;
            color: #737D8C;
            line-height: 1.4rem;
        }
    }
}
