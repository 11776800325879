.mx_RoomTile.ck_RoomTile:not(.ctalk_room_detail) {
    margin-bottom: unset;
    padding: 0px 4px;

    min-height: 69px;

    .ck_favourite_icon {
        height: 13px;
    }

    &:last-child {
        margin-bottom: 1px;
    }

    &:hover,
    &:focus-within,
    &.mx_RoomTile_hasMenuOpen {
        border-radius: unset;
        &:not(.mx_RoomTile_selected) {
            background-color: $panel-actions;
        }
        .ck_RoomTile_title_name_wrapper {
            .ck_favourite_icon {
                display: none;
            }
        }
        .ck_favourite_icon {
            display: unset;
        }
    }

    &.mx_RoomTile_selected {
        border-radius: unset;
        background-color: $roomtile-active-bg-color;

        .mx_RoomTile_title,
        .ck_RoomTile_subtitle_sender,
        .mx_RoomTile_subtitle_text,
        .ck_MessageTimestamp {
            color: $tab-label-active-fg-color;
        }

        .ck_RoomTile_group_icon,
        .ck_RoomTile_server_notice_icon,
        .ck_favourite_icon {
            background: $tab-label-active-fg-color;
        }

        .mx_RoomTile_subtitle_text {
            opacity: 0.7;
        }

        .mx_RoomTile_menuButton,
        .mx_RoomTile_notificationsButton {
            &::before {
                background: $tab-label-active-fg-color;
            }
        }

        .ctalk_AutoDeleteMessage_roomMarker {
            &:after {
                outline: dashed 2px $tab-label-active-fg-color;
            }
        }

        .mx_DecoratedRoomAvatar,
        .mx_RoomTile_badgeContainer {
            .mx_NotificationBadge {
                &.mx_NotificationBadge_visible {
                    background-color: $tab-label-active-fg-color;
                    &.mx_NotificationBadge_dot {
                        background-color: $tab-label-active-fg-color;
                    }
                }
                .mx_NotificationBadge_count {
                    color: var(--cpd-color-green-ctalk);
                }
            }
        }
    }

    &:not(.mx_RoomTile_selected) {
        .ck_RoomTile_subtitle_sender {
            color: var(--cpd-color-text-primary);
        }

        .mx_RoomTile_subtitle_text {
            color: var(--cpd-color-gray-800);
        }
    }

    .mx_DecoratedRoomAvatar,
    .mx_RoomTile_avatarContainer {
        display: flex;
        align-items: center;
        padding-left: 4px;

        .mx_DecoratedRoomAvatar_icon {
            position: absolute;
            bottom: 16.25%;
            right: -0.75%;
            width: 25%;
            height: 25%;
            border-radius: 50%;
        }

        .mx_DecoratedRoomAvatar_icon::before {
            content: "";
            width: 20px;
            height: 20px;
            right: 0;
            position: absolute;
            border-radius: 10px;
        }
    }

    .ck_RoomTile_container {
        display: flex;
        width: 100%;
        border-bottom: 1px solid var(--cpd-color-alpha-gray-400);
        overflow: hidden;
    }

    .mx_RoomTile_titleContainer {
        height: 68px;
        margin-right: unset;

        .ck_RoomTile_title_name_wrapper {
            display: flex;
            justify-content: space-between;
            min-height: 22px;
            .ck_favourite_icon {
                padding-top: 2px;
            }
        }

        .mx_RoomTile_title {
            font: var(--cpd-font-body-md-regular);
            font-weight: var(--cpd-font-weight-medium);
            line-height: 1.25;
            padding-bottom: 3px;
            display: flex;
            align-items: center;

            &.mx_RoomTile_titleHasUnreadEvents {
                font-weight: var(--cpd-font-weight-medium);
            }

            &:has(.ck_RoomTile_group_icon, .ck_RoomTile_server_notice_icon) {
                display: flex;
                align-items: center;
            }

            .ck_RoomTile_title_name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .mx_RoomTile_subtitle {
            top: unset;
            column-gap: unset;

            .ck_RoomTile_subtitleContainer {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                font-size: 12px;
                row-gap: 3px;
                width: 100%;
                min-height: 34px;

                .ck_RoomTile_subtitle_sender {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .ck_RoomTile_subtitle_wrapper_text {
                    display: flex;
                    justify-content: space-between;
                }
            }

            .ck_RoomTile_subtitle_badgeContainer {
                height: 0;
                &.ck_RoomTile_subtitle_badgeContainer_dm {
                    height: 100%;
                }
            }
        }
    }

    &.mx_RoomTile_minimized {
        padding: 6px;

        .ck_RoomTile_container {
            border-bottom: unset;
        }

        .mx_DecoratedRoomAvatar,
        .mx_RoomTile_avatarContainer {
            padding-left: unset;
        }
    }

    &:not(.mx_RoomTile_minimized, .mx_RoomTile_sticky)&:has(.ck_RoomTile_subtitle_wrapper_text) {
        &:hover,
        &:focus-within,
        &.mx_RoomTile_hasMenuOpen {
            .mx_RoomTile_badgeContainer {
                width: unset;
                height: 16px;
                display: flex;
            }

            .mx_RoomTile_menuButton {
                display: none;
            }
        }
    }
}

.mx_RoomTile {
    .ck_favourite_icon {
        height: unset;
    }
    &:hover,
    &:focus-within,
    &.mx_RoomTile_hasMenuOpen {
        &:not(.ck_RoomTile_title_name_wrapper) {
            .ck_favourite_icon {
                display: none;
            }
        }
    }
}

.ck_RoomTile_group_icon {
    margin-right: 2px;
    min-width: 13px;
    height: 13px;
    left: 0;
    mask-image: url('$(web_res)/themes/element/icons/group.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 13px;
    background: var(--cpd-color-text-action-primary);
}

.ck_RoomTile_server_notice_icon {
    margin-right: 2px;
    min-width: 13px;
    height: 13px;
    left: 0;
    mask-image: url('$(web_res)/themes/element/icons/chat-folder-icons/commercial.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 13px;
    background: var(--cpd-color-text-action-primary);
}

.ck_favourite_icon {
    margin-right: 2px;
    min-width: 13px;
    height: 13px;
    left: 0;
    mask-image: url('$(web_res)/themes/element/icons/chat-folder-icons/star.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 13px;
    background: var(--cpd-color-gray-800);
}

.mx_RoomTile_contextMenu, .mx_ContextualMenu {
    .ck_RoomTile_iconAddToFolder::before {
        mask-image: url("$(web_res)/themes/element/icons/add-to-folder.svg");
        mask-size: 20px;
    }
}

.ck_MessageTimestamp {
    color: var(--MessageTimestamp-color);
    font-size: 0.7rem;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    display: block;
    white-space: nowrap;
    padding-bottom: 4px;
}
