.ctalk_QRLogin_main {
    display: flex;
    justify-content: space-between;
    height: 234px;
}

.ctalk_QRLogin_QRCode {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid var(--cpd-color-text-action-accent);
    overflow: hidden;
}

.ctalk_QRLogin_tutorial {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .ctalk_QRLogin_steps {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        font-size: $font-13px;
    }

    .ctalk_QRLogin_step {
        display: inline-flex;
        line-height: 2rem;
        padding-bottom: 10px;
    }
}

.ctalk_QRLogin_stepNo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cpd-color-text-action-accent);
    color: var(--cpd-color-bg-canvas-default);
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 5px;
}

.ctalk_QRLogin_reloadBtn {
    text-transform: uppercase;
    width: 50%;
}
.ctalk_QRLogin_backBtn {
    width: 85%;
}

.ctalk_QRLogin_error {
    text-align: center;
}
