.cpd-theme-dark.cpd-theme-dark {
  --cpd-color-alpha-pink-1400: hsl(347, 100%, 96%, 1);
  --cpd-color-alpha-pink-1300: hsl(347, 100%, 91%, 1);
  --cpd-color-alpha-pink-1200: hsl(346, 100%, 84%, 1);
  --cpd-color-alpha-pink-1100: hsl(345, 98%, 76%, 1);
  --cpd-color-alpha-pink-1000: hsla(343, 100%, 70%, 0.98);
  --cpd-color-alpha-pink-900: hsla(340, 99%, 63%, 0.96);
  --cpd-color-alpha-pink-800: hsla(335, 99%, 55%, 0.8);
  --cpd-color-alpha-pink-700: hsla(334, 98%, 53%, 0.58);
  --cpd-color-alpha-pink-600: hsla(333, 97%, 50%, 0.46);
  --cpd-color-alpha-pink-500: hsl(330, 100%, 21%, 1);
  --cpd-color-alpha-pink-400: hsl(335, 100%, 17%, 1);
  --cpd-color-alpha-pink-300: hsl(339, 100%, 14%, 1);
  --cpd-color-alpha-pink-200: hsl(342, 100%, 12%, 1);
  --cpd-color-alpha-pink-100: hsl(344, 100%, 11%, 1);
  --cpd-color-alpha-fuchsia-1400: hsla(296, 90%, 96%, 0.98);
  --cpd-color-alpha-fuchsia-1300: hsla(296, 100%, 94%, 0.95);
  --cpd-color-alpha-fuchsia-1200: hsla(296, 97%, 88%, 0.91);
  --cpd-color-alpha-fuchsia-1100: hsla(297, 98%, 82%, 0.86);
  --cpd-color-alpha-fuchsia-1000: hsla(296, 98%, 78%, 0.83);
  --cpd-color-alpha-fuchsia-900: hsla(295, 97%, 72%, 0.8);
  --cpd-color-alpha-fuchsia-800: hsla(294, 98%, 63%, 0.71);
  --cpd-color-alpha-fuchsia-700: hsla(289, 99%, 59%, 0.54);
  --cpd-color-alpha-fuchsia-600: hsla(288, 100%, 56%, 0.44);
  --cpd-color-alpha-fuchsia-500: hsla(287, 100%, 52%, 0.38);
  --cpd-color-alpha-fuchsia-400: hsl(285, 100%, 18%, 1);
  --cpd-color-alpha-fuchsia-300: hsl(282, 100%, 15%, 1);
  --cpd-color-alpha-fuchsia-200: hsl(281, 100%, 13%, 1);
  --cpd-color-alpha-fuchsia-100: hsl(279, 100%, 12%, 1);
  --cpd-color-alpha-purple-1400: hsl(249, 100%, 96%, 1);
  --cpd-color-alpha-purple-1300: hsl(246, 100%, 93%, 1);
  --cpd-color-alpha-purple-1200: hsl(249, 100%, 87%, 1);
  --cpd-color-alpha-purple-1100: hsl(250, 98%, 80%, 1);
  --cpd-color-alpha-purple-1000: hsla(252, 98%, 77%, 0.99);
  --cpd-color-alpha-purple-900: hsla(254, 99%, 72%, 0.98);
  --cpd-color-alpha-purple-800: hsla(257, 100%, 65%, 0.92);
  --cpd-color-alpha-purple-700: hsla(260, 98%, 58%, 0.76);
  --cpd-color-alpha-purple-600: hsla(263, 98%, 52%, 0.67);
  --cpd-color-alpha-purple-500: hsl(263, 100%, 31%, 1);
  --cpd-color-alpha-purple-400: hsl(261, 100%, 25%, 1);
  --cpd-color-alpha-purple-300: hsl(259, 100%, 21%, 1);
  --cpd-color-alpha-purple-200: hsl(259, 100%, 18%, 1);
  --cpd-color-alpha-purple-100: hsl(258, 100%, 17%, 1);
  --cpd-color-alpha-blue-1400: hsl(217, 93%, 95%, 1);
  --cpd-color-alpha-blue-1300: hsla(216, 96%, 90%, 0.99);
  --cpd-color-alpha-blue-1200: hsla(216, 100%, 82%, 0.97);
  --cpd-color-alpha-blue-1100: hsla(215, 97%, 74%, 0.96);
  --cpd-color-alpha-blue-1000: hsla(216, 99%, 69%, 0.94);
  --cpd-color-alpha-blue-900: hsla(215, 98%, 63%, 0.92);
  --cpd-color-alpha-blue-800: hsla(214, 99%, 53%, 0.84);
  --cpd-color-alpha-blue-700: hsla(217, 99%, 51%, 0.64);
  --cpd-color-alpha-blue-600: hsla(219, 99%, 50%, 0.53);
  --cpd-color-alpha-blue-500: hsla(221, 100%, 37%, 0.63);
  --cpd-color-alpha-blue-400: hsl(224, 100%, 22%, 1);
  --cpd-color-alpha-blue-300: hsl(229, 100%, 20%, 1);
  --cpd-color-alpha-blue-200: hsl(234, 100%, 18%, 1);
  --cpd-color-alpha-blue-100: hsl(237, 100%, 18%, 1);
  --cpd-color-alpha-cyan-1400: hsla(187, 93%, 94%, 0.96);
  --cpd-color-alpha-cyan-1300: hsla(187, 93%, 89%, 0.92);
  --cpd-color-alpha-cyan-1200: hsla(187, 100%, 77%, 0.85);
  --cpd-color-alpha-cyan-1100: hsla(186, 98%, 57%, 0.79);
  --cpd-color-alpha-cyan-1000: hsla(189, 100%, 44%, 0.88);
  --cpd-color-alpha-cyan-900: hsl(194, 100%, 37%, 1);
  --cpd-color-alpha-cyan-800: hsla(200, 100%, 37%, 0.88);
  --cpd-color-alpha-cyan-700: hsl(204, 100%, 27%, 1);
  --cpd-color-alpha-cyan-600: hsl(208, 100%, 23%, 1);
  --cpd-color-alpha-cyan-500: hsl(210, 100%, 20%, 1);
  --cpd-color-alpha-cyan-400: hsl(215, 100%, 18%, 1);
  --cpd-color-alpha-cyan-300: hsl(219, 100%, 15%, 1);
  --cpd-color-alpha-cyan-200: hsl(223, 100%, 14%, 1);
  --cpd-color-alpha-cyan-100: hsl(225, 100%, 13%, 1);
  --cpd-color-alpha-green-1400: hsla(152, 88%, 94%, 0.96);
  --cpd-color-alpha-green-1300: hsla(152, 93%, 88%, 0.91);
  --cpd-color-alpha-green-1200: hsla(156, 97%, 76%, 0.83);
  --cpd-color-alpha-green-1100: hsla(162, 98%, 57%, 0.74);
  --cpd-color-alpha-green-1000: hsla(163, 99%, 55%, 0.65);
  --cpd-color-alpha-green-900: hsla(164, 98%, 53%, 0.58);
  --cpd-color-alpha-green-800: hsl(168, 100%, 24%, 1);
  --cpd-color-alpha-green-700: hsl(165, 100%, 18%, 1);
  --cpd-color-alpha-green-600: hsl(162, 100%, 14%, 1);
  --cpd-color-alpha-green-500: hsl(160, 100%, 12%, 1);
  --cpd-color-alpha-green-400: hsl(155, 100%, 9%, 1);
  --cpd-color-alpha-green-300: hsl(151, 100%, 7%, 1);
  --cpd-color-alpha-green-200: hsl(147, 100%, 6%, 1);
  --cpd-color-alpha-green-100: hsl(144, 100%, 6%, 1);
  --cpd-color-alpha-lime-1400: hsla(105, 91%, 92%, 0.97);
  --cpd-color-alpha-lime-1300: hsla(104, 100%, 84%, 0.92);
  --cpd-color-alpha-lime-1200: hsla(102, 100%, 68%, 0.84);
  --cpd-color-alpha-lime-1100: hsla(102, 98%, 60%, 0.74);
  --cpd-color-alpha-lime-1000: hsla(105, 97%, 58%, 0.66);
  --cpd-color-alpha-lime-900: hsla(107, 98%, 57%, 0.58);
  --cpd-color-alpha-lime-800: hsla(112, 98%, 52%, 0.45);
  --cpd-color-alpha-lime-700: hsl(120, 100%, 18%, 1);
  --cpd-color-alpha-lime-600: hsl(120, 100%, 15%, 1);
  --cpd-color-alpha-lime-500: hsl(120, 100%, 12%, 1);
  --cpd-color-alpha-lime-400: hsl(120, 100%, 9%, 1);
  --cpd-color-alpha-lime-300: hsl(120, 100%, 8%, 1);
  --cpd-color-alpha-lime-200: hsl(120, 100%, 6%, 1);
  --cpd-color-alpha-lime-100: hsl(120, 100%, 5%, 1);
  --cpd-color-alpha-yellow-1400: hsl(46, 100%, 85%, 1);
  --cpd-color-alpha-yellow-1300: hsl(47, 99%, 67%, 1);
  --cpd-color-alpha-yellow-1200: hsla(46, 98%, 52%, 0.94);
  --cpd-color-alpha-yellow-1100: hsl(44, 100%, 43%, 1);
  --cpd-color-alpha-yellow-1000: hsl(41, 100%, 40%, 1);
  --cpd-color-alpha-yellow-900: hsl(39, 100%, 37%, 1);
  --cpd-color-alpha-yellow-800: hsl(35, 100%, 31%, 1);
  --cpd-color-alpha-yellow-700: hsla(30, 100%, 26%, 0.92);
  --cpd-color-alpha-yellow-600: hsla(26, 100%, 23%, 0.87);
  --cpd-color-alpha-yellow-500: hsl(23, 100%, 18%, 1);
  --cpd-color-alpha-yellow-400: hsl(16, 100%, 15%, 1);
  --cpd-color-alpha-yellow-300: hsl(8, 100%, 13%, 1);
  --cpd-color-alpha-yellow-200: hsl(3, 100%, 11%, 1);
  --cpd-color-alpha-yellow-100: hsl(0, 100%, 11%, 1);
  --cpd-color-alpha-orange-1400: hsl(25, 100%, 93%, 1);
  --cpd-color-alpha-orange-1300: hsl(24, 100%, 86%, 1);
  --cpd-color-alpha-orange-1200: hsla(26, 97%, 75%, 0.99);
  --cpd-color-alpha-orange-1100: hsla(27, 98%, 62%, 0.97);
  --cpd-color-alpha-orange-1000: hsla(29, 99%, 53%, 0.92);
  --cpd-color-alpha-orange-900: hsla(26, 99%, 52%, 0.85);
  --cpd-color-alpha-orange-800: hsla(21, 100%, 50%, 0.71);
  --cpd-color-alpha-orange-700: hsla(12, 100%, 39%, 0.74);
  --cpd-color-alpha-orange-600: hsl(2, 100%, 26%, 1);
  --cpd-color-alpha-orange-500: hsl(0, 100%, 22%, 1);
  --cpd-color-alpha-orange-400: hsl(0, 100%, 17%, 1);
  --cpd-color-alpha-orange-300: hsl(0, 100%, 14%, 1);
  --cpd-color-alpha-orange-200: hsl(0, 100%, 12%, 1);
  --cpd-color-alpha-orange-100: hsl(0, 100%, 11%, 1);
  --cpd-color-alpha-red-1400: hsl(7, 100%, 95%, 1);
  --cpd-color-alpha-red-1300: hsl(8, 100%, 90%, 1);
  --cpd-color-alpha-red-1200: hsl(7, 100%, 82%, 1);
  --cpd-color-alpha-red-1100: hsl(5, 100%, 74%, 1);
  --cpd-color-alpha-red-1000: hsl(3, 100%, 68%, 1);
  --cpd-color-alpha-red-900: hsl(1, 98%, 61%, 1);
  --cpd-color-alpha-red-800: hsla(357, 99%, 57%, 0.81);
  --cpd-color-alpha-red-700: hsla(354, 99%, 52%, 0.6);
  --cpd-color-alpha-red-600: hsl(356, 100%, 26%, 1);
  --cpd-color-alpha-red-500: hsl(0, 100%, 22%, 1);
  --cpd-color-alpha-red-400: hsl(0, 100%, 18%, 1);
  --cpd-color-alpha-red-300: hsl(0, 100%, 14%, 1);
  --cpd-color-alpha-red-200: hsl(0, 100%, 12%, 1);
  --cpd-color-alpha-red-100: hsl(0, 100%, 11%, 1);
  --cpd-color-alpha-gray-1400: hsla(214, 78%, 98%, 0.95);
  --cpd-color-alpha-gray-1300: hsla(213, 73%, 97%, 0.89);
  --cpd-color-alpha-gray-1200: hsla(215, 74%, 96%, 0.79);
  --cpd-color-alpha-gray-1100: hsla(215, 92%, 95%, 0.68);
  --cpd-color-alpha-gray-1000: hsla(213, 93%, 94%, 0.61);
  --cpd-color-alpha-gray-900: hsla(211, 94%, 94%, 0.54);
  --cpd-color-alpha-gray-800: hsla(216, 100%, 94%, 0.41);
  --cpd-color-alpha-gray-700: hsla(213, 85%, 95%, 0.27);
  --cpd-color-alpha-gray-600: hsla(225, 46%, 95%, 0.2);
  --cpd-color-alpha-gray-500: hsla(214, 41%, 97%, 0.15);
  --cpd-color-alpha-gray-400: hsla(270, 37%, 93%, 0.1);
  --cpd-color-alpha-gray-300: hsla(280, 41%, 90%, 0.06);
  --cpd-color-alpha-gray-200: hsla(286, 31%, 82%, 0.04);
  --cpd-color-alpha-gray-100: hsla(214, 10%, 86%, 0.02);
  --cpd-color-pink-1400: #ffe8ed;
  --cpd-color-pink-1300: #ffd2dc;
  --cpd-color-pink-1200: #ffabbe;
  --cpd-color-pink-1100: #fe84a2;
  --cpd-color-pink-1000: #fa658f;
  --cpd-color-pink-900: #f4427d;
  --cpd-color-pink-800: #ce1865;
  --cpd-color-pink-700: #99114f;
  --cpd-color-pink-600: #7c0c41;
  --cpd-color-pink-500: #6d0036;
  --cpd-color-pink-400: #550024;
  --cpd-color-pink-300: #450018;
  --cpd-color-pink-200: #3c0012;
  --cpd-color-pink-100: #37000f;
  --cpd-color-fuchsia-1400: #f8e9f9;
  --cpd-color-fuchsia-1300: #f1d4f3;
  --cpd-color-fuchsia-1200: #e5b1e9;
  --cpd-color-fuchsia-1100: #d991de;
  --cpd-color-fuchsia-1000: #cf78d7;
  --cpd-color-fuchsia-900: #c560cf;
  --cpd-color-fuchsia-800: #aa36ba;
  --cpd-color-fuchsia-700: #7d2394;
  --cpd-color-fuchsia-600: #65177d;
  --cpd-color-fuchsia-500: #560f6f;
  --cpd-color-fuchsia-400: #46005e;
  --cpd-color-fuchsia-300: #37004e;
  --cpd-color-fuchsia-200: #2e0044;
  --cpd-color-fuchsia-100: #28003d;
  --cpd-color-purple-1400: #eeebff;
  --cpd-color-purple-1300: #dedaff;
  --cpd-color-purple-1200: #c4baff;
  --cpd-color-purple-1100: #ad9cfe;
  --cpd-color-purple-1000: #9e87fc;
  --cpd-color-purple-900: #9171f9;
  --cpd-color-purple-800: #7849ec;
  --cpd-color-purple-700: #5a27c6;
  --cpd-color-purple-600: #4a0db1;
  --cpd-color-purple-500: #3d009e;
  --cpd-color-purple-400: #2c0080;
  --cpd-color-purple-300: #22006a;
  --cpd-color-purple-200: #1c005a;
  --cpd-color-purple-100: #1a0055;
  --cpd-color-blue-1400: #e4eefe;
  --cpd-color-blue-1300: #cbdffc;
  --cpd-color-blue-1200: #a1c4f8;
  --cpd-color-blue-1100: #7aacf4;
  --cpd-color-blue-1000: #5e99f0;
  --cpd-color-blue-900: #4187eb;
  --cpd-color-blue-800: #0e67d9;
  --cpd-color-blue-700: #0b49ab;
  --cpd-color-blue-600: #083891;
  --cpd-color-blue-500: #062d80;
  --cpd-color-blue-400: #001e6f;
  --cpd-color-blue-300: #001264;
  --cpd-color-blue-200: #00095d;
  --cpd-color-blue-100: #00055a;
  --cpd-color-cyan-1400: #dbf2f5;
  --cpd-color-cyan-1300: #b8e5eb;
  --cpd-color-cyan-1200: #78d0dc;
  --cpd-color-cyan-1100: #21bacd;
  --cpd-color-cyan-1000: #02a7c6;
  --cpd-color-cyan-900: #0093be;
  --cpd-color-cyan-800: #0271aa;
  --cpd-color-cyan-700: #005188;
  --cpd-color-cyan-600: #003f75;
  --cpd-color-cyan-500: #003468;
  --cpd-color-cyan-400: #002559;
  --cpd-color-cyan-300: #001b4e;
  --cpd-color-cyan-200: #001448;
  --cpd-color-cyan-100: #001144;
  --cpd-color-green-1400: #d9f4e7;
  --cpd-color-green-1300: #b5e8d1;
  --cpd-color-green-1200: #72d5ae;
  --cpd-color-green-1100: #1fc090;
  --cpd-color-green-1000: #17ac84;
  --cpd-color-green-ctalk: #0dbd8b;
  --cpd-color-green-900: #129a78;
  --cpd-color-green-800: #007a62;
  --cpd-color-green-700: #005a43;
  --cpd-color-green-600: #004832;
  --cpd-color-green-500: #003d29;
  --cpd-color-green-400: #002e1b;
  --cpd-color-green-300: #002513;
  --cpd-color-green-200: #001f0e;
  --cpd-color-green-100: #001c0b;
  --cpd-color-lime-1400: #daf6d0;
  --cpd-color-lime-1300: #b6eca3;
  --cpd-color-lime-1200: #77d94f;
  --cpd-color-lime-1100: #56c02c;
  --cpd-color-lime-1000: #47ad26;
  --cpd-color-lime-900: #389b20;
  --cpd-color-lime-800: #1d7c13;
  --cpd-color-lime-700: #005c00;
  --cpd-color-lime-600: #004a00;
  --cpd-color-lime-500: #003e00;
  --cpd-color-lime-400: #003000;
  --cpd-color-lime-300: #002600;
  --cpd-color-lime-200: #002000;
  --cpd-color-lime-100: #001b00;
  --cpd-color-yellow-1400: #ffedb1;
  --cpd-color-yellow-1300: #fedb58;
  --cpd-color-yellow-1200: #efbb0b;
  --cpd-color-yellow-1100: #db9f00;
  --cpd-color-yellow-1000: #cc8c00;
  --cpd-color-yellow-900: #bc7a00;
  --cpd-color-yellow-800: #9d5b00;
  --cpd-color-yellow-700: #7c3e02;
  --cpd-color-yellow-600: #682e03;
  --cpd-color-yellow-500: #5c2400;
  --cpd-color-yellow-400: #4c1400;
  --cpd-color-yellow-300: #410900;
  --cpd-color-yellow-200: #3a0300;
  --cpd-color-yellow-100: #360000;
  --cpd-color-orange-1400: #ffeadb;
  --cpd-color-orange-1300: #ffd5b9;
  --cpd-color-orange-1200: #fbb37e;
  --cpd-color-orange-1100: #f6913d;
  --cpd-color-orange-1000: #eb7a12;
  --cpd-color-orange-900: #da670d;
  --cpd-color-orange-800: #b94607;
  --cpd-color-orange-700: #972206;
  --cpd-color-orange-600: #830500;
  --cpd-color-orange-500: #710000;
  --cpd-color-orange-400: #580000;
  --cpd-color-orange-300: #470000;
  --cpd-color-orange-200: #3c0000;
  --cpd-color-orange-100: #380000;
  --cpd-color-red-1400: #ffe9e6;
  --cpd-color-red-1300: #ffd4cd;
  --cpd-color-red-1200: #ffaea4;
  --cpd-color-red-1100: #ff877c;
  --cpd-color-red-1000: #ff665d;
  --cpd-color-red-900: #fd3e3c;
  --cpd-color-red-800: #d1212a;
  --cpd-color-red-700: #9f0d1e;
  --cpd-color-red-600: #830009;
  --cpd-color-red-500: #710000;
  --cpd-color-red-400: #590000;
  --cpd-color-red-300: #470000;
  --cpd-color-red-200: #3e0000;
  --cpd-color-red-100: #370000;
  --cpd-color-gray-1400: #ebeef2;
  --cpd-color-gray-1300: #d9dee4;
  --cpd-color-gray-1200: #bdc3cc;
  --cpd-color-gray-1100: #a3aab4;
  --cpd-color-gray-1000: #9199a4;
  --cpd-color-gray-900: #808994;
  --cpd-color-gray-800: #656c76;
  --cpd-color-gray-700: #4a4f55;
  --cpd-color-gray-600: #3c3f44;
  --cpd-color-gray-500: #323539;
  --cpd-color-gray-400: #26282d;
  --cpd-color-gray-300: #1d1f24;
  --cpd-color-gray-200: #181a1f;
  --cpd-color-gray-100: #14171b;
  --cpd-color-theme-bg: #101317;
  --cpd-color-bg-subtle-secondary-level-0: var(--cpd-color-theme-bg);
  --cpd-color-bg-canvas-default-level-1: var(--cpd-color-gray-300);
  --cpd-color-sticky-date-background: #2B2B2B66;
  --cpd-color-sticky-date-text: #ffffff;
}
