.ctalk_RoomInvalid_Container {
    z-index: 1;
    text-align: center;
    h1 {
        font-size: 1.2rem;
        font-weight: 500;
    }
    .ctalk_RoomInvalid_Icon_Lock {
        display: inline-block;
        justify-content: center;
        height: 125px;
        width: 125px;
        svg path {
            stroke: var(--cpd-color-text-primary);
            stroke-width: 1;
        }
        //&:before {
        //    content: "";
        //    display: block;
        //    height: 125px;
        //    width: 125px;
        //    background-size: 125px 125px;
        //    background-image: url('$(web_res)/themes/element/icons/lock.svg');
        //    background-repeat: no-repeat;
        //    filter: brightness(400%);
        //}
    }

    .ctalk_AccessibleButton_Reject {
        padding-top: 10px;
    }
}
