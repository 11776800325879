.mx_EventTile_msgOption {
    width: unset !important;
}

.mx_EventTile_msgOption {
    -webkit-margin-end: unset !important;
    margin-inline-end: unset !important;
}

.ctalk_ReadReceiptGroup_button {
    transform: scaleX(-1);
    width: 0.9rem;
}

.ctalk_PinnedIcon path {
    fill: var(--cpd-color-text-action-accent);;
}
