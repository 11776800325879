.ck_Manual_Login_select_server {
    .ck_Select_server_container {
        margin-bottom: 40px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(141, 156, 165, .2);
    }
}
.ck_Login_Loader .mx_Spinner {
    text-align: center;
    display: flex;
}
.ck_AuthHeader {
    background-color: #FFFFFF;
    border-bottom-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.ck_Auth_Login_QR {
    width: 706px;
    font-size: 1.2rem;
    color: #61708b;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 25px;
    box-sizing: border-box
}
