.ctalk_LocalPasscodePanel {
    margin-inline-end: 100px;
    position: relative;
    hr {
        border: none;
        border-bottom: 1px solid var(--cpd-color-border-info-subtle);
    }
}

.ctalk_LocalPasscodePanel_Error {
    color: var(--cpd-color-text-critical-primary);
    font-size: $font-14px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.ctalk_LocalPasscodePanel_Input_Note {
    font-size: $font-12px;
}

.ctalk_LocalPasscodePanel_IdleTimeoutForm {
    .mx_Field {
        max-width: 128px;
    }

    .mx_IdleTimeoutUnit {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.ctalk_LocalPasscodePanel_IdleTimeout_OptionCustom {
    display: flex;
    justify-content: start;
    align-items: center;
    .mx_StyledRadioButton {
        flex-grow: 0;
        width: 25px;
    }
}

.ctalk_LocalPasscodePanel_IdleTimeout_ValueWrapper {
    display: flex;
    align-items: center;
}
.ctalk_LocalPasscodePanel_AutoLockLabel {
    max-width: 392px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ctalk_LocalPasscodePanel_moreSettings {
    margin: 20px 0;
}

.ctalk_LocalPasscodePanel_EditIcon  {
    position: relative;
    top: -6px;
    margin-left: 5px;
    &:before {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        width: 32px;
        height: 32px;
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
        background-color: var(--cpd-color-text-secondary);
        /*noinspection CssUnknownTarget*/
        mask-image: url('$(web_res)/themes/element/icons/edit.svg');
    }
}

.ctalk_LocalPasscodePanel_unlockPasscode {
    .mx_SettingsSubsectionHeading_heading {
        margin-bottom: 10px;
    }
}

.mx_LeftPanel_wrapper--user[data-collapsed="true"] {
    .ctalk_LeftPanel_lockButton {
        margin-left: 0;
        margin-top: 8px;
    }
}

.ctalk_LeftPanel_lockButton {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    position: relative;
    margin-left: 8px;
    background-color: var(--cpd-color-alpha-gray-300);

    &:hover {
        background-color: var(--cpd-color-gray-800);
        &::before {
            background-color: var(--cpd-color-bg-canvas-default);
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 8px;
        width: 16px;
        height: 16px;
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
        background-color: var(--cpd-color-text-secondary);
        /*noinspection CssUnknownTarget*/
        mask-image: url('$(web_res)/themes/element/icons/unlock.svg');
    }
}

.visually-hidden {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    z-index: -1;
}
