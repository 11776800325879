.ck_RoomSearchView_ContextMenu {
    position: absolute;
    background-color: var(--cpd-color-bg-canvas-default);
    border: 1px solid var(--cpd-color-bg-subtle-secondary);
    border-radius: 4px;
    width: 100%;
    .ck_RoomSearchView_ContextMenu_NoResults {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ck_RoomSearchView_ContextMenu_LoadingMore {
        text-align: center;
        padding: 8px;
    }
    .ck_RoomSearchView_ContextMenu_List {
        max-height: 40vh;
        overflow-y: auto;
        .ck_RoomSearchView_ContextMenu_Item {
            border-top: 1px solid var(--cpd-color-bg-subtle-secondary);
            border-bottom: 1px solid var(--cpd-color-bg-subtle-secondary);

            &:first-child {
                border-top: none;
            }
            &:last-child {
                border-bottom: none;
            }

            .ck_RoomSearchView_ContextMenu_Item_Button {
                all: unset;
                display: block;
                width: 100%;
                text-align: left;
                cursor: pointer;
                transition: background-color 0.2s ease-in-out;
            }

            .ck_RoomSearchView_ContextMenu_Item_Container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .ck_RoomSearchView_ContextMenu_Item_Avatar {
                margin-right: 12px;
            }
            .ck_RoomSearchView_ContextMenu_Item_Info {
                flex: 1;
                min-width: 0;
                overflow: hidden;
                .ck_RoomSearchView_ContextMenu_Item_RoomName {
                    font-weight: bold;
                }
                .ck_RoomSearchView_ContextMenu_Item_SenderName_AllRoom {
                    font-size: 12px;
                    color: var(--cpd-color-gray-800);
                    margin-bottom: 0;
                }
                .ck_RoomSearchView_ContextMenu_Item_SenderName {
                    font-weight: bold;
                    margin-bottom: 4px;
                }
                .ck_RoomSearchView_ContextMenu_Item_MessageContent {
                    font-size: 12px;
                    color: var(--cpd-color-gray-800);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .mx_EventTile_searchHighlight {
                        background-color: var(--cpd-color-text-action-accent);
                        border-radius: 5px;
                        cursor: pointer;
                        padding-inline: 3px;
                        color: $accent-fg-color;
                    }
                }
            }
            .ck_RoomSearchView_ContextMenu_Item_FormattedDate {
                width: 100px;
                margin-left: auto;
                color: var(--cpd-color-gray-800);
                font-size: 12px;
                flex-shrink: 0;
                text-align: end;
            }
        }
    }
}
