.mx_SpacePanel {
    & > .mx_AutoHideScrollbar {
        flex: unset;
    }

    .mx_SpaceButton {
        &.mx_SpaceButton_narrow {
            width: unset;
        }
        &.mx_SpaceButton_chatfolder {
            .mx_SpaceButton_icon {
                background-color: $panel-actions;

                &::before {
                    background-color: $secondary-content;
                }
            }
        }

        &.mx_SpaceButton_active {
            &.mx_SpaceButton_narrow .mx_SpaceButton_selectionWrapper {
                max-width: 32px;
            }
        }

        &.ck_SpaceButton_invites {
            .mx_SpaceButton_icon {
                background-color: $panel-actions;

                &::before {
                    background-color: $secondary-content;
                }
            }
        }

        &.ck_SpaceButton_invites .mx_SpaceButton_icon::before {
            mask-image: url("$(web_res)/themes/element/icons/user_fill_add.svg");
        }

        &.ck_SpaceButton_unread {
            .mx_SpaceButton_icon {
                background-color: $panel-actions;

                &::before {
                    background-color: $secondary-content;
                    mask-image: url("$(web_res)/themes/element/icons/message_unread.svg");
                }
            }
        }
    }

    .mx_SpaceItem:not(.mx_SpaceItem_new):has(.mx_SpaceButton_chatfolder) {
        .mx_SpaceButton:hover,
        .mx_SpaceButton:focus-within,
        .mx_SpaceButton_hasMenuOpen {
            &:not(.mx_SpaceButton_narrow):not(.mx_SpaceButton_invite) .mx_SpaceButton_name {
                max-width: unset;
            }
        }
    }

    .ck_ChatFolderPanel {
        flex: 1;
    }
}

.mx_QuickSettingsButton {
    &::before {
        mask-image: url("$(web_res)/themes/element/icons/settings.svg");
        mask-size: 32px;
    }
}

.mx_QuickSettingsButton_ContextMenuWrapper .mx_ContextualMenu {
    .ck_QuickSettingsButton_unreadCheckbox {
        .mx_Checkbox_background + div {
            padding-left: 22px;
            position: relative;
            margin-left: 6px;
            font-size: $font-15px;
            line-height: $font-24px;
            color: $secondary-content;
        }
    }
}
