.ctalk_MImageBody_description {
    color: inherit;
    white-space: pre-wrap;
}

.mx_MImageBody,
.ctalk_MImageBody_image {
    position: relative;
    .mx_HiddenImagePlaceholder {
        min-height: 50px;
    }
}
.ctalk_MImageBody_image[data-layout="bubble"]{
    background: var(--backgroundColor);
    .ctalk_MImageBody_description {
        padding-left: 10px;
    }
}

.ctalk_MImageBody_banner_filename {
    z-index: 2;
}

.ctalk_MImageBody_thumbnail[data-layout="bubble"]{
    /*noinspection CssUnresolvedCustomProperty*/
    background: var(--backgroundColor);
    .ctalk_MImageBody_description {
        padding-left: 10px;
        padding-right: 10px;
        white-space: pre-wrap;
    }
    .ctalk_MImageBody_Banner_Reply {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:has(.ctalk_MImageBody_description) {
        .mx_MImageBody_placeholder {
            height: unset;
            background: unset;
        }
    }
}

.ctalk_MImageBody_Preview {
    overflow: hidden;
    display: flex;
    align-items: center;

    .ctalk_MImageBody_Background {
        filter: blur(30px);
        opacity: 0.7;
        object-fit: cover;
        max-width: 320px;
        user-select: none;
        pointer-events: none;
        min-height: 50px;
    }
    .ctalk_MImageBody_Background_Large {
        max-width: unset;
    }
    .ctalk_MImageBody_Banner {
        position: absolute;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        object-fit: contain;
    }
}
.ctalk_MImageBody_thumbnail_container,
.ctalk_HiddenImagePlaceholder {
    .ctalk_HiddenImagePlaceholder_download {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: hsla(215, 96%, 10%, 0.35);
        padding: 8px;
        border-radius: 50%;
        &::after {
            content: "";
            display: block;
            background-color: #ffffff;
            width: 18px;
            height: 18px;
            mask-size: 18px;
            mask-position: center;
            mask-repeat: no-repeat;
            opacity: 90%;
            mask-image: url('~matrix-react-sdk/res/img/download.svg');
        }
    }
    .mx_HiddenImagePlaceholder_button {
        width: 100%;
        height: 100%;
        position: relative;
    }
}

.mx_EventTile[data-layout="bubble"] .mx_EventTile_line .ctalk_MImageBody_Large {
    height: unset;
}

.mx_RightPanel {
    .mx_FilePanel {
        .ctalk_MImageBody_image {
            .ctalk_MImageBody_Preview {
                max-height: 200px;
                .ctalk_MImageBody_Banner,
                .ctalk_MImageBody_Background {
                    max-height: 200px;
                }
            }
        }
    }
}

.ctalk_MImageBody_Banner {
    height: 100%;
    width: auto;
}

.ctalk_MImageBody_Banner_Forward_No_Des {
    height: fit-content;
}

.ctalk_MImageBody_Banner_Forward_Mini_Height,
.ctalk_MImageBody_Banner_Larger {
    width: 100%;
}

.mx_ReplyPreview {
    .ctalk_MImageBody_Error {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.ctalk_MImageBody_Error {
    min-width: 200px;
    min-height: 40px;
    color: var(--cpd-color-text-secondary);
    .ctalk_EventTile_content {
        color: var(--cpd-color-text-primary);
    }
}

.mx_ReplyChain {
    .ctalk_MImageBody_Error {
        min-width: unset;
        min-height: unset;
    }
}
.ctalk_EventTile_Reply_Content:has(.ctalk_MImageBody_Error) {
    min-width: 200px;
    min-height: 40px;
}

.mx_EventTile[data-layout="bubble"] {
    .mx_EventTile_line.mx_EventTile_mediaLine.mx_EventTile_image:has(.ctalk_MImageBody_Error) {
        .mx_MessageTimestamp {
            color: var(--MessageTimestamp-color);
            border-radius: unset;
            background-color: unset;
            padding: unset;
        }
    }

    .ctalk_EventTile_message_forward {
        &:not(.ctalk_MImageBody_description) {
            .ctalk_MImageBody_Preview {
                overflow: unset;
            }
        }
        &:has(.ctalk_MImageBody_description) {
            .ctalk_MImageBody_Preview {
                overflow: hidden;
            }
        }
    }
}
