.ck_EditHistory_popup {
    max-height: 300px;
    min-width: 200px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 0.8125em;
    right: 0;

    &.mx_ContextualMenu_top {
        top: 8px;
    }

    &.mx_ContextualMenu_bottom {
        bottom: 8px;
    }

    .ck_EditHistory_title {
        margin: 16px 16px 8px;
        font-weight: var(--cpd-font-weight-semibold);
        outline: none;
    }

    .mx_AutoHideScrollbar {
        .ck_EditHistory_item,
        .ck_EditHistory_error,
        .ck_EditHistory_loading {
            display: flex;
            flex-direction: row;
            padding: 4px;
            margin: 0 12px;
            border-radius: 8px;

            &:hover {
                background: $menu-selected-color;
            }

            &:last-child {
                margin-bottom: 8px;
            }
        }

        .ck_EditHistory_item {
            justify-content: flex-start;
            white-space: nowrap;
        }

        .ck_EditHistory_loading,
        .ck_EditHistory_error {
            justify-content: center;
        }

        .ck_EditHistory_error {
            color: var(--cpd-color-text-critical-primary);
            font-weight: var(--cpd-font-weight-medium);
        }
    }
}
