:root {
  --cpd-color-alpha-pink-1400: hsl(347, 100%, 97%, 1);
  --cpd-color-alpha-pink-1300: hsl(347, 100%, 96%, 1);
  --cpd-color-alpha-pink-1200: hsl(347, 100%, 90%, 1);
  --cpd-color-alpha-pink-1100: hsl(347, 100%, 87%, 1);
  --cpd-color-alpha-pink-1000: hsl(346, 100%, 82%, 1);
  --cpd-color-alpha-pink-900: hsl(346, 100%, 79%, 1);
  --cpd-color-alpha-pink-800: hsla(338, 98%, 58%, 0.95);
  --cpd-color-alpha-pink-700: hsla(335, 99%, 55%, 0.76);
  --cpd-color-alpha-pink-600: hsla(334, 98%, 53%, 0.58);
  --cpd-color-alpha-pink-500: hsla(333, 97%, 50%, 0.46);
  --cpd-color-alpha-pink-400: hsl(332, 100%, 19%, 1);
  --cpd-color-alpha-pink-300: hsl(335, 100%, 17%, 1);
  --cpd-color-alpha-pink-200: hsl(339, 100%, 14%, 1);
  --cpd-color-alpha-pink-100: hsl(342, 100%, 12%, 1);
  --cpd-color-alpha-fuchsia-1400: hsla(300, 71%, 97%, 0.99);
  --cpd-color-alpha-fuchsia-1300: hsla(296, 90%, 96%, 0.98);
  --cpd-color-alpha-fuchsia-1200: hsla(297, 95%, 92%, 0.95);
  --cpd-color-alpha-fuchsia-1100: hsla(296, 92%, 90%, 0.93);
  --cpd-color-alpha-fuchsia-1000: hsla(296, 97%, 87%, 0.9);
  --cpd-color-alpha-fuchsia-900: hsla(295, 100%, 85%, 0.88);
  --cpd-color-alpha-fuchsia-800: hsla(296, 99%, 70%, 0.78);
  --cpd-color-alpha-fuchsia-700: hsla(293, 97%, 62%, 0.68);
  --cpd-color-alpha-fuchsia-600: hsla(289, 99%, 59%, 0.54);
  --cpd-color-alpha-fuchsia-500: hsla(288, 100%, 56%, 0.44);
  --cpd-color-alpha-fuchsia-400: hsla(286, 100%, 23%, 0.85);
  --cpd-color-alpha-fuchsia-300: hsl(285, 100%, 18%, 1);
  --cpd-color-alpha-fuchsia-200: hsl(282, 100%, 15%, 1);
  --cpd-color-alpha-fuchsia-100: hsl(281, 100%, 13%, 1);
  --cpd-color-alpha-purple-1400: hsl(250, 100%, 98%, 1);
  --cpd-color-alpha-purple-1300: hsl(249, 100%, 96%, 1);
  --cpd-color-alpha-purple-1200: hsl(247, 100%, 92%, 1);
  --cpd-color-alpha-purple-1100: hsl(248, 100%, 89%, 1);
  --cpd-color-alpha-purple-1000: hsl(249, 100%, 86%, 1);
  --cpd-color-alpha-purple-900: hsl(250, 100%, 83%, 1);
  --cpd-color-alpha-purple-800: hsla(255, 100%, 71%, 0.97);
  --cpd-color-alpha-purple-700: hsla(258, 98%, 64%, 0.9);
  --cpd-color-alpha-purple-600: hsla(260, 98%, 58%, 0.76);
  --cpd-color-alpha-purple-500: hsla(263, 98%, 52%, 0.67);
  --cpd-color-alpha-purple-400: hsl(262, 100%, 28%, 1);
  --cpd-color-alpha-purple-300: hsl(261, 100%, 25%, 1);
  --cpd-color-alpha-purple-200: hsl(259, 100%, 21%, 1);
  --cpd-color-alpha-purple-100: hsl(259, 100%, 18%, 1);
  --cpd-color-alpha-blue-1400: hsl(216, 88%, 97%, 1);
  --cpd-color-alpha-blue-1300: hsl(217, 93%, 95%, 1);
  --cpd-color-alpha-blue-1200: hsla(216, 97%, 89%, 0.99);
  --cpd-color-alpha-blue-1100: hsla(216, 100%, 86%, 0.98);
  --cpd-color-alpha-blue-1000: hsla(216, 100%, 81%, 0.97);
  --cpd-color-alpha-blue-900: hsla(216, 97%, 77%, 0.97);
  --cpd-color-alpha-blue-800: hsla(215, 99%, 60%, 0.91);
  --cpd-color-alpha-blue-700: hsla(215, 98%, 52%, 0.81);
  --cpd-color-alpha-blue-600: hsla(217, 99%, 51%, 0.64);
  --cpd-color-alpha-blue-500: hsla(219, 99%, 50%, 0.53);
  --cpd-color-alpha-blue-400: hsla(222, 100%, 28%, 0.82);
  --cpd-color-alpha-blue-300: hsl(224, 100%, 22%, 1);
  --cpd-color-alpha-blue-200: hsl(229, 100%, 20%, 1);
  --cpd-color-alpha-blue-100: hsl(234, 100%, 18%, 1);
  --cpd-color-alpha-cyan-1400: hsla(187, 89%, 97%, 0.98);
  --cpd-color-alpha-cyan-1300: hsla(187, 93%, 94%, 0.96);
  --cpd-color-alpha-cyan-1200: hsla(188, 97%, 87%, 0.91);
  --cpd-color-alpha-cyan-1100: hsla(187, 98%, 82%, 0.88);
  --cpd-color-alpha-cyan-1000: hsla(187, 99%, 74%, 0.84);
  --cpd-color-alpha-cyan-900: hsla(186, 99%, 66%, 0.81);
  --cpd-color-alpha-cyan-800: hsl(195, 100%, 37%, 1);
  --cpd-color-alpha-cyan-700: hsl(200, 100%, 32%, 1);
  --cpd-color-alpha-cyan-600: hsl(204, 100%, 27%, 1);
  --cpd-color-alpha-cyan-500: hsl(208, 100%, 23%, 1);
  --cpd-color-alpha-cyan-400: hsl(212, 100%, 19%, 1);
  --cpd-color-alpha-cyan-300: hsl(215, 100%, 18%, 1);
  --cpd-color-alpha-cyan-200: hsl(219, 100%, 15%, 1);
  --cpd-color-alpha-cyan-100: hsl(223, 100%, 14%, 1);
  --cpd-color-alpha-green-1400: hsla(150, 80%, 96%, 0.98);
  --cpd-color-alpha-green-1300: hsla(152, 88%, 94%, 0.96);
  --cpd-color-alpha-green-1200: hsla(153, 94%, 87%, 0.9);
  --cpd-color-alpha-green-1100: hsla(154, 98%, 82%, 0.86);
  --cpd-color-alpha-green-1000: hsla(156, 100%, 73%, 0.81);
  --cpd-color-alpha-green-900: hsla(159, 97%, 62%, 0.78);
  --cpd-color-alpha-green-800: hsla(165, 98%, 53%, 0.54);
  --cpd-color-alpha-green-700: hsl(168, 100%, 23%, 1);
  --cpd-color-alpha-green-600: hsl(165, 100%, 18%, 1);
  --cpd-color-alpha-green-500: hsl(162, 100%, 14%, 1);
  --cpd-color-alpha-green-400: hsl(158, 100%, 11%, 1);
  --cpd-color-alpha-green-300: hsl(155, 100%, 9%, 1);
  --cpd-color-alpha-green-200: hsl(151, 100%, 7%, 1);
  --cpd-color-alpha-green-100: hsl(147, 100%, 6%, 1);
  --cpd-color-alpha-lime-1400: hsla(104, 92%, 95%, 0.98);
  --cpd-color-alpha-lime-1300: hsla(105, 91%, 92%, 0.97);
  --cpd-color-alpha-lime-1200: hsla(103, 98%, 83%, 0.91);
  --cpd-color-alpha-lime-1100: hsla(103, 97%, 75%, 0.88);
  --cpd-color-alpha-lime-1000: hsla(101, 99%, 62%, 0.83);
  --cpd-color-alpha-lime-900: hsla(101, 99%, 60%, 0.78);
  --cpd-color-alpha-lime-800: hsla(109, 99%, 57%, 0.55);
  --cpd-color-alpha-lime-700: hsla(114, 100%, 52%, 0.42);
  --cpd-color-alpha-lime-600: hsl(120, 100%, 18%, 1);
  --cpd-color-alpha-lime-500: hsl(120, 100%, 15%, 1);
  --cpd-color-alpha-lime-400: hsl(120, 100%, 11%, 1);
  --cpd-color-alpha-lime-300: hsl(120, 100%, 9%, 1);
  --cpd-color-alpha-lime-200: hsl(120, 100%, 8%, 1);
  --cpd-color-alpha-lime-100: hsl(120, 100%, 6%, 1);
  --cpd-color-alpha-yellow-1400: hsl(46, 100%, 91%, 1);
  --cpd-color-alpha-yellow-1300: hsl(46, 100%, 85%, 1);
  --cpd-color-alpha-yellow-1200: hsl(48, 99%, 60%, 1);
  --cpd-color-alpha-yellow-1100: hsla(48, 99%, 54%, 0.97);
  --cpd-color-alpha-yellow-1000: hsla(46, 99%, 51%, 0.92);
  --cpd-color-alpha-yellow-900: hsl(45, 100%, 45%, 1);
  --cpd-color-alpha-yellow-800: hsl(38, 100%, 35%, 1);
  --cpd-color-alpha-yellow-700: hsl(34, 100%, 30%, 1);
  --cpd-color-alpha-yellow-600: hsla(30, 100%, 26%, 0.92);
  --cpd-color-alpha-yellow-500: hsla(26, 100%, 23%, 0.87);
  --cpd-color-alpha-yellow-400: hsl(21, 100%, 17%, 1);
  --cpd-color-alpha-yellow-300: hsl(16, 100%, 15%, 1);
  --cpd-color-alpha-yellow-200: hsl(8, 100%, 13%, 1);
  --cpd-color-alpha-yellow-100: hsl(3, 100%, 11%, 1);
  --cpd-color-alpha-orange-1400: hsl(23, 100%, 96%, 1);
  --cpd-color-alpha-orange-1300: hsl(25, 100%, 93%, 1);
  --cpd-color-alpha-orange-1200: hsl(24, 98%, 85%, 1);
  --cpd-color-alpha-orange-1100: hsl(25, 96%, 79%, 1);
  --cpd-color-alpha-orange-1000: hsla(26, 100%, 73%, 0.98);
  --cpd-color-alpha-orange-900: hsla(26, 98%, 67%, 0.98);
  --cpd-color-alpha-orange-800: hsla(26, 99%, 51%, 0.81);
  --cpd-color-alpha-orange-700: hsla(20, 100%, 49%, 0.7);
  --cpd-color-alpha-orange-600: hsla(12, 100%, 39%, 0.74);
  --cpd-color-alpha-orange-500: hsl(2, 100%, 26%, 1);
  --cpd-color-alpha-orange-400: hsl(0, 100%, 20%, 1);
  --cpd-color-alpha-orange-300: hsl(0, 100%, 17%, 1);
  --cpd-color-alpha-orange-200: hsl(0, 100%, 14%, 1);
  --cpd-color-alpha-orange-100: hsl(0, 100%, 12%, 1);
  --cpd-color-alpha-red-1400: hsl(11, 100%, 97%, 1);
  --cpd-color-alpha-red-1300: hsl(7, 100%, 95%, 1);
  --cpd-color-alpha-red-1200: hsl(8, 100%, 89%, 1);
  --cpd-color-alpha-red-1100: hsl(6, 100%, 86%, 1);
  --cpd-color-alpha-red-1000: hsl(6, 100%, 81%, 1);
  --cpd-color-alpha-red-900: hsl(5, 100%, 78%, 1);
  --cpd-color-alpha-red-800: hsla(359, 100%, 59%, 0.96);
  --cpd-color-alpha-red-700: hsla(357, 100%, 57%, 0.77);
  --cpd-color-alpha-red-600: hsla(354, 99%, 52%, 0.6);
  --cpd-color-alpha-red-500: hsl(356, 100%, 26%, 1);
  --cpd-color-alpha-red-400: hsl(0, 100%, 20%, 1);
  --cpd-color-alpha-red-300: hsl(0, 100%, 18%, 1);
  --cpd-color-alpha-red-200: hsl(0, 100%, 14%, 1);
  --cpd-color-alpha-red-100: hsl(0, 100%, 12%, 1);
  --cpd-color-alpha-gray-1400: hsla(204, 71%, 99%, 0.97);
  --cpd-color-alpha-gray-1300: hsla(214, 78%, 98%, 0.95);
  --cpd-color-alpha-gray-1200: hsla(217, 77%, 97%, 0.88);
  --cpd-color-alpha-gray-1100: hsla(212, 100%, 97%, 0.82);
  --cpd-color-alpha-gray-1000: hsla(213, 100%, 97%, 0.76);
  --cpd-color-alpha-gray-900: hsla(211, 91%, 96%, 0.72);
  --cpd-color-alpha-gray-800: hsla(216, 94%, 93%, 0.51);
  --cpd-color-alpha-gray-700: hsla(214, 79%, 93%, 0.39);
  --cpd-color-alpha-gray-600: hsla(213, 85%, 95%, 0.27);
  --cpd-color-alpha-gray-500: hsla(225, 46%, 95%, 0.2);
  --cpd-color-alpha-gray-400: hsla(227, 29%, 91%, 0.13);
  --cpd-color-alpha-gray-300: hsla(270, 37%, 93%, 0.1);
  --cpd-color-alpha-gray-200: hsla(280, 41%, 90%, 0.06);
  --cpd-color-alpha-gray-100: hsla(286, 31%, 82%, 0.04);
  --cpd-color-pink-1400: #fff1f4;
  --cpd-color-pink-1300: #ffe8ed;
  --cpd-color-pink-1200: #ffccd7;
  --cpd-color-pink-1100: #ffbbca;
  --cpd-color-pink-1000: #ffa4b9;
  --cpd-color-pink-900: #ff92ac;
  --cpd-color-pink-800: #f12c75;
  --cpd-color-pink-700: #c51761;
  --cpd-color-pink-600: #99114f;
  --cpd-color-pink-500: #7c0c41;
  --cpd-color-pink-400: #61002d;
  --cpd-color-pink-300: #550024;
  --cpd-color-pink-200: #450018;
  --cpd-color-pink-100: #3c0012;
  --cpd-color-fuchsia-1400: #fbf1fb;
  --cpd-color-fuchsia-1300: #f8e9f9;
  --cpd-color-fuchsia-1200: #f0cff2;
  --cpd-color-fuchsia-1100: #eac0ed;
  --cpd-color-fuchsia-1000: #e3abe7;
  --cpd-color-fuchsia-900: #dd9de3;
  --cpd-color-fuchsia-800: #c153cb;
  --cpd-color-fuchsia-700: #a233b3;
  --cpd-color-fuchsia-600: #7d2394;
  --cpd-color-fuchsia-500: #65177d;
  --cpd-color-fuchsia-400: #4f0368;
  --cpd-color-fuchsia-300: #46005e;
  --cpd-color-fuchsia-200: #37004e;
  --cpd-color-fuchsia-100: #2e0044;
  --cpd-color-purple-1400: #f5f3ff;
  --cpd-color-purple-1300: #eeebff;
  --cpd-color-purple-1200: #dad5ff;
  --cpd-color-purple-1100: #cec7ff;
  --cpd-color-purple-1000: #c0b5ff;
  --cpd-color-purple-900: #b6a7ff;
  --cpd-color-purple-800: #8b66f8;
  --cpd-color-purple-700: #7343e6;
  --cpd-color-purple-600: #5a27c6;
  --cpd-color-purple-500: #4a0db1;
  --cpd-color-purple-400: #350090;
  --cpd-color-purple-300: #2c0080;
  --cpd-color-purple-200: #22006a;
  --cpd-color-purple-100: #1c005a;
  --cpd-color-blue-1400: #eff5fe;
  --cpd-color-blue-1300: #e4eefe;
  --cpd-color-blue-1200: #c5dbfc;
  --cpd-color-blue-1100: #b2cffa;
  --cpd-color-blue-1000: #9ac0f8;
  --cpd-color-blue-900: #89b5f6;
  --cpd-color-blue-800: #337fe9;
  --cpd-color-blue-700: #0e61d1;
  --cpd-color-blue-600: #0b49ab;
  --cpd-color-blue-500: #083891;
  --cpd-color-blue-400: #032677;
  --cpd-color-blue-300: #001e6f;
  --cpd-color-blue-200: #001264;
  --cpd-color-blue-100: #00095d;
  --cpd-color-cyan-1400: #eaf7f9;
  --cpd-color-cyan-1300: #dbf2f5;
  --cpd-color-cyan-1200: #afe2e9;
  --cpd-color-cyan-1100: #93d9e2;
  --cpd-color-cyan-1000: #6bccd9;
  --cpd-color-cyan-900: #46c3d2;
  --cpd-color-cyan-800: #008aba;
  --cpd-color-cyan-700: #006ca4;
  --cpd-color-cyan-600: #005188;
  --cpd-color-cyan-500: #003f75;
  --cpd-color-cyan-400: #002d61;
  --cpd-color-cyan-300: #002559;
  --cpd-color-cyan-200: #001b4e;
  --cpd-color-cyan-100: #001448;
  --cpd-color-green-1400: #e9f8f1;
  --cpd-color-green-1300: #d9f4e7;
  --cpd-color-green-1200: #ace6cc;
  --cpd-color-green-1100: #8fddbc;
  --cpd-color-green-1000: #61d2a6;
  --cpd-color-green-900: #37c998;
  --cpd-color-green-ctalk: #0dbd8b;
  --cpd-color-green-800: #109173;
  --cpd-color-green-700: #00745c;
  --cpd-color-green-600: #005a43;
  --cpd-color-green-500: #004832;
  --cpd-color-green-400: #003622;
  --cpd-color-green-300: #002e1b;
  --cpd-color-green-200: #002513;
  --cpd-color-green-100: #001f0e;
  --cpd-color-lime-1400: #e9f9e3;
  --cpd-color-lime-1300: #daf6d0;
  --cpd-color-lime-1200: #afe99a;
  --cpd-color-lime-1100: #92e175;
  --cpd-color-lime-1000: #6ad639;
  --cpd-color-lime-900: #5eca2f;
  --cpd-color-lime-800: #31941d;
  --cpd-color-lime-700: #187611;
  --cpd-color-lime-600: #005c00;
  --cpd-color-lime-500: #004a00;
  --cpd-color-lime-400: #003700;
  --cpd-color-lime-300: #003000;
  --cpd-color-lime-200: #002600;
  --cpd-color-lime-100: #002000;
  --cpd-color-yellow-1400: #fff4d0;
  --cpd-color-yellow-1300: #ffedb1;
  --cpd-color-yellow-1200: #fed632;
  --cpd-color-yellow-1100: #f7c816;
  --cpd-color-yellow-1000: #ebb607;
  --cpd-color-yellow-900: #e3aa00;
  --cpd-color-yellow-800: #b47200;
  --cpd-color-yellow-700: #985600;
  --cpd-color-yellow-600: #7c3e02;
  --cpd-color-yellow-500: #682e03;
  --cpd-color-yellow-400: #541d00;
  --cpd-color-yellow-300: #4c1400;
  --cpd-color-yellow-200: #410900;
  --cpd-color-yellow-100: #3a0300;
  --cpd-color-orange-1400: #fff2ea;
  --cpd-color-orange-1300: #ffeadb;
  --cpd-color-orange-1200: #fed0b1;
  --cpd-color-orange-1100: #fdc197;
  --cpd-color-orange-1000: #faad73;
  --cpd-color-orange-900: #f89d58;
  --cpd-color-orange-800: #d15f0b;
  --cpd-color-orange-700: #b44007;
  --cpd-color-orange-600: #972206;
  --cpd-color-orange-500: #830500;
  --cpd-color-orange-400: #650000;
  --cpd-color-orange-300: #580000;
  --cpd-color-orange-200: #470000;
  --cpd-color-orange-100: #3c0000;
  --cpd-color-red-1400: #fff2ef;
  --cpd-color-red-1300: #ffe9e6;
  --cpd-color-red-1200: #ffcfc8;
  --cpd-color-red-1100: #ffbdb5;
  --cpd-color-red-1000: #ffa79d;
  --cpd-color-red-900: #ff968c;
  --cpd-color-red-800: #f52f33;
  --cpd-color-red-700: #c81e28;
  --cpd-color-red-600: #9f0d1e;
  --cpd-color-red-500: #830009;
  --cpd-color-red-400: #640000;
  --cpd-color-red-300: #590000;
  --cpd-color-red-200: #470000;
  --cpd-color-red-100: #3e0000;
  --cpd-color-gray-1400: #f2f5f7;
  --cpd-color-gray-1300: #ebeef2;
  --cpd-color-gray-1200: #d5dae1;
  --cpd-color-gray-1100: #c8ced5;
  --cpd-color-gray-1000: #b8bfc7;
  --cpd-color-gray-900: #acb4bd;
  --cpd-color-gray-800: #79818d;
  --cpd-color-gray-700: #606770;
  --cpd-color-gray-600: #4a4f55;
  --cpd-color-gray-500: #3c3f44;
  --cpd-color-gray-400: #2b2e33;
  --cpd-color-gray-300: #26282d;
  --cpd-color-gray-200: #1d1f24;
  --cpd-color-gray-100: #181a1f;
  --cpd-color-theme-bg: #101317;
  --cpd-color-bg-subtle-secondary-level-0: var(--cpd-color-theme-bg);
  --cpd-color-bg-canvas-default-level-1: var(--cpd-color-gray-300);
  --cpd-color-sticky-date-background: #2B2B2B66;
  --cpd-color-sticky-date-text: #ffffff;
}
