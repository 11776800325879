.mx_Dialog_background {
    pointer-events: none !important;
}

.mx_MediaBody {
    color: #b0b0b0;
}

.mx_EventTile_content .markdown-body {
    h1 {
        font-size: $font-23px;
    }

    h2 {
        font-size: $font-20px;
    }

    h3 {
        font-size: $font-18px;
    }

    h4 {
        font-size: $font-17px;
    }

    h5 {
        font-size: $font-15px;
    }

    h6 {
        font-size: $font-14px;
    }

    span {
        &[data-mx-color="primary"] {
            color: var(--cpd-color-text-action-accent);
        }

        &[data-mx-color="red"] {
            color: rgb(235, 78, 59);
        }

        &[data-mx-color="yellow"] {
            color: rgb(247, 206, 70);
        }

        &[data-mx-color="green"] {
            color: rgb(100, 197, 100);
        }

        &[data-mx-color="blue"] {
            color: rgb(35, 140, 245);
        }

        &[data-mx-color="magenta"] {
            color: rgb(255, 0, 255);
        }

        &[data-mx-color="cyan"] {
            color: rgb(121, 199, 246);
        }
    }
}
