.ctalk_EventTile_message_forward {
    overflow: hidden;
}

.ctalk_MessageEvent_forward {
    color: var(--cpd-color-text-action-accent);
    font-size: $font-13px;
    padding-bottom: 5px;
    .mx_BaseAvatar {
        min-width: $font-14px;
        margin: 0 5px -2px 0;
    }
    .ctalk_MessageEvent_forwardTitle {
        line-height: 1rem;
        margin-right: 5px;
    }
    .ctalk_MessageEvent_forwardUser {
        white-space: nowrap;
    }
    .ctalk_MessageEvent_forwardName {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: calc(100% - 19px);
        display: inline-block;
        line-height: 1rem;
        margin-bottom: -2px;
        font-weight: 500;
    }
}

.ctalk_ReplyChain_error {
    font-size: 12.5px;
}

.mx_MessageContextMenu_iconSource_View_Relations::before {
    mask-image: url("~matrix-react-sdk/res/img/element-icons/message/overflow-large.svg")
}
