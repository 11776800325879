.ctalk_MVideoBody_description {
    color: inherit;
    white-space: pre-wrap;
}

.ctalk_EventTile_mediaLine {
    .ctalk_MVideoBody_description,
    .ctalk_MVideoBody_error {
        white-space: pre-wrap;
        width: 100%;
        padding: 0 10px 10px;
    }

    .ctalk_EventTile_message_forward {
        .ctalk_MVideoBody {
            .ctalk_MVideoBody_error {
                margin-top: 0;
            }
            .ctalk_MVideoBody_description {
                padding-bottom: 10px;
            }
        }
    }
}
.ck_MVideoBody_Caption_Error {
    color: var(--cpd-color-text-primary);
}

.ck_MVideoBody_Caption_Preview_Reply {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ctalk_Body_error {
    overflow: hidden;
    min-width: 200px;
    color: darkgray;
    .ctalk_MVideoBody_container {
        border-bottom-left-radius: 0 !important;
    }
}

.ctalk_EventTile_Reply_Messages {
    .ctalk_MVideoBody {
        height: 100%;
    }
}

.ctalk_EventTile_Reply_Content:has(.ctalk_Body_error) {
    min-width: 200px;
}

.ctalk_MVideoBody_Placeholder_Safari {
    text-align: center;
}

.ctalk_MVideoBody_Placeholder {
    position: relative;
    .ctalk_MVideoBody_Placeholder_button {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        &_download {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: hsla(215, 96%, 10%, 0.35);
            padding: 8px;
            border-radius: 50%;
            cursor: pointer;
            &::after {
                content: "";
                display: block;
                background-color: #ffffff;
                width: 18px;
                height: 18px;
                mask-size: 18px;
                mask-position: center;
                mask-repeat: no-repeat;
                opacity: 90%;
                mask-image: url('~matrix-react-sdk/res/img/download.svg');
            }
        }
    }
}
