.mx_TwoFactorDialog {
    background-color: var(--cpd-color-bg-canvas-default);
    max-width: 350px;
    padding: 15px;
    border-radius: 5px;

    .mx_Dialog_buttons {
        .btn-none-border {
            border: none !important;
        }
    }
}
.mx_TwoFactorDialog_ErrorText {
    color: var(--cpd-color-text-critical-primary);
}
