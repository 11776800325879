.ck_AddRoomToFolderDialog_wrapper {
    .mx_Dialog {
        display: flex;
        flex-direction: column;
    }
}

.ck_AddRoomToFolderDialog {
    width: 75vh;
    color: $primary-content;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 0;
    max-height: 75vh;

    .mx_AddExistingToSpace {
        display: contents;
    }
}

.ck_AddRoomToFolder {
    margin-top: 10px;

    .ck_AddRoomToFolder_content {
        flex-grow: 1;
    }

    .ck_AddRoomToFolder_noResults {
        display: block;
        margin-top: 24px;
    }

    .ck_AddRoomToFolder_section {
        margin-right: 12px;

        // provides space for scrollbar so that checkbox and scrollbar do not collide

        &:not(:first-child) {
            margin-top: 24px;
        }

        > h2 {
            margin: 0;
            color: $secondary-content;
            font-size: $font-14px;
            font-weight: var(--cpd-font-weight-semibold);
            line-height: $font-15px;
        }

        .mx_AccessibleButton_kind_link {
            font-size: $font-15px;
            line-height: $font-15px;
            margin-top: 8px;
        }

        .ck_AddRoomToFolder_list_folders {
            overflow-y: auto;
            max-height: 380px;
            min-height: 150px;
        }

        .ck_AddRoomToFolder_empty_folders {
            margin-top: 5px;
            color: var(--cpd-color-text-secondary);
            font-size: 0.875rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .ck_FolderItem_icon {
            padding-top: 3px;
        }
    }
}

.ck_AddRoomToFolder_footer {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;
}


.ck_AddRoomToFolder_entry {
    display: flex;
    margin-top: 12px;
    padding-right: 10px;

    .ck_AddRoomToFolder_entry_name {
        font-size: $font-15px;
        line-height: 30px;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 12px;
    }

    .mx_Checkbox {
        align-items: center;
    }
}

