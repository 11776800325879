.ctalk_userInfoLogin {
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-size: $font-13px;
    margin: 10px 0;
    background: #eeeeee;

    &-icon {
        border-radius: 10px;
        height: 20px;
        width: 20px;
    }

    &-info {
        .mx_AccessibleButton {
            width: 239px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            text-align: center;
            &:focus-visible {
                outline: none;
            }
        }
    }

    &-action {
        height: 20px;
        &:focus-visible {
            outline: none;
        }
    }
}
