.ck_SendWysiwygComposer {
    margin-right: 0;
    grid-gap: unset;
    gap: unset;
    .mx_WysiwygComposer_Editor {
        border: unset;
    }

    .ck_FormattingButtons_show {
        display: flex;
    }

    .ck_FormattingButtons {
        justify-content: flex-start;
        gap: 8px;
        // 7 items
        width: calc(33px* 7);
        .mx_FormattingButtons_Button {
            --size: 28px;
            cursor: pointer;
            height: var(--size);
            width: var(--size);
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
        }

        .mx_FormattingButtons_Button_hover {
            &:hover {
                background: $panels;

                .mx_FormattingButtons_Icon {
                    color: $secondary-content;
                }
            }
        }

        .mx_FormattingButtons_active {
            background: $accent-300;

            .mx_FormattingButtons_Icon {
                color: $accent;
            }
        }

        .mx_FormattingButtons_disabled {
            .mx_FormattingButtons_Icon {
                color: $quinary-content;
            }
        }

        .mx_FormattingButtons_Icon {
            --size: 16px;
            height: var(--size);
            width: var(--size);
            color: $tertiary-content;
        }
    }

    .mx_FormattingButtons_Tooltip {
        padding: 0 2px 0 2px;

        .mx_FormattingButtons_Tooltip_KeyboardShortcut {
            color: $tertiary-content;

            kbd {
                margin-top: 2px;
                text-align: center;
                display: inline-block;
                text-transform: capitalize;
                font-size: 12px;
                font-family: Inter, sans-serif;
            }
        }
    }
}
.ck_SendWysiwygComposer_Right, .ck_SendWysiwygComposer_Left {
    display: flex;
    align-items: center;
    gap: 8px;
}
.ck_SendWysiwygComposer_Left {
    .mx_E2EIcon {
        margin: 0;
    }
}

.ck_CaptionWysiwygComposer {
    .ck_CaptionWysiwygComposer_RightComponent {
        .ck_CaptionWysiwygComposer_RightComponent_Container{
            display: flex;
            align-items: center;
            .ck_CaptionWysiwygComposer_RightComponent_Emoji {
                padding-right: 10px;
            }
        }
    }
    .ck_WysiwygComposer_Editor_content {
        color: var(--cpd-color-text-primary);
    }
    .ck_WysiwygComposer_Editor_container {
        overflow-y: auto;
        max-height: 300px;
        white-space: normal;
        overflow-wrap: break-word;
    }
}

.ck_MessageComposer_File::before {
    mask-image: url('$(web_res)/themes/element/icons/file.svg');
}

.ck_MessageComposer_PhotoOrVideo::before {
    mask-image: url('$(web_res)/themes/element/icons/image.svg');
}

.ck_MessageComposer_Change_Composer::before {
    mask-image: url("$(res)/img/element-icons/settings/keyboard.svg");
}

.mx_IconizedContextMenu.mx_IconizedContextMenu_compact .mx_IconizedContextMenu_optionList {
    .ck_MessageComposer_separator {
        padding: 0;
    }
    .ck_MessageComposer_changeComposerButton {
        padding-top: 15px;
    }
}

.ck_MessageComposer_separator {
    border-top: 1px solid var(--cpd-color-gray-400);
    margin: 5px auto;
    width: 70%;
}

.ck_MessageComposer_newComposer {
    position: relative;
}

.ck_MessageComposer_betaLabel {
    position: absolute;
    top: -22px;
    right: 0px;
    background-color: var(--cpd-color-text-action-accent);;
    color: var(--cpd-color-bg-canvas-default);
    font-size: 9px;
    padding: 1px 6px;
    border-radius: 4px;
    font-weight: bold;
}
