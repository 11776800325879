.ctalk_Dialog_titleImage_dark {
    filter: brightness(1000);
}

.ctalk_QuestionDialog_sizeSmall {
    max-width: 400px;
    .mx_Dialog_content {
        margin-bottom: 30px;
    }
}
