.mx_SpotlightDialog {
    .mx_Dialog_header.mx_Dialog_headerWithCancel {
        display: block;
        .mx_AccessibleButton {
            margin-right: 15px;
            margin-top: 10px;
        }
        .mx_Dialog_cancelButton {
            mask-size: 18px;
        }
    }
    .mx_SpotlightDialog_searchBox {
        & > .mx_Spinner {
            position: absolute;
            right: 50px;
        }
    }
    .ck_SpotlightDialog_filterBot {
        &:before {
            /*noinspection CssUnknownTarget*/
            mask-image: url('$(web_res)/themes/element/icons/bot-icon.svg');
        }
    }
    #mx_SpotlightDialog_content {
        .ck_SpotlightDialog_botChat {
            &:before {
                background-color: var(--cpd-color-text-secondary);
                content: "";
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: contain;
                width: 24px;
                height: 24px;
                position: absolute;
                left: 4px;
                top: 50%;
                transform: translateY(-50%);
                /*noinspection CssUnknownTarget*/
                mask-image: url('$(web_res)/themes/element/icons/bot-icon.svg');
            }
            &.mx_SpotlightDialog_option {
                padding-left: $spacing-32;
                position: relative;
            }
        }
    }
}
