$reactionsAvatarSize: 20px;
.mx_ReactionsRow {
    .mx_ReactionsRow_showAll {
        margin-inline-end: 4px;
    }
}
.mx_ReactionsRowButton {
    border-radius: 15px;
    font-size: 18px;
    position: relative;
    z-index: 1;

    &.mx_ReactionsRowButton_selected {
        background-color: var(--cpd-color-green-ctalk);
        .mx_ReactionsRowButton_count {
            color: white;
        }
    }
    .mx_ReactionsRowButton_voters {
        position: relative;
        display: inline-flex;
        .mx_BaseAvatar {
            position: relative;
            &:not(:first-child) {
                height: $reactionsAvatarSize;
            }
            &:first-child {
                z-index: 10;
                float: left;
            }
            &:nth-child(2) {
                position: absolute;
                left: 12px;
                z-index: 5;
            }
            &:nth-child(3) {
                position: absolute;
                left: 24px;
                z-index: 1;
            }
        }
    }

    .mx_ReactionsRowButton_count {
        font-size: $font-15px;
        margin: 0 3px;
        color: var(--cpd-color-green-ctalk);
    }
}

.ctalk_ReactionsRow_contextMenu {
    $itemHeight: $reactionsAvatarSize;

    &.ctalk_ReactionsRow_contextOverflow {
        max-height: 540px; // (20 + (8 * 2)) * 15
        overflow-y: scroll;
        .mx_IconizedContextMenu_optionList:first-child .mx_IconizedContextMenu_item {
            &:first-child {
                border-top-right-radius: 0;
            }
            &:last-child {
                border-bottom-right-radius: 0;
            }
        }
    }
    .ctalk_ReactionsRow_contextMore {
        text-align: center;
        width: 100%;
        font-style: italic;
    }
    .mx_IconizedContextMenu_item {
        .mx_BaseAvatar {
            outline: solid 1px var(--cpd-color-text-on-solid-primary);
            img {
                width: $reactionsAvatarSize;
                min-width: $reactionsAvatarSize;
                min-height: $reactionsAvatarSize;
            }
        }
        &:hover {
            .ctalk_ReactionsRow_contextItem > div {
                top: -$itemHeight;
            }
        }
    }
    .ctalk_ReactionsRow_contextItem {
        flex: 1 0;
        min-width: 170px;
        min-height: $itemHeight;
        height: $itemHeight;
        max-height: $itemHeight;
        margin-left: 5px;
        overflow: hidden;
        & > div {
            top: 0;
            position: relative;
            height: $itemHeight;
            width: 100%;
            transition: top 0.2s ease 0s;
        }
    }
    .ctalk_ReactionsRow_contextItemUserName {
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .ctalk_ReactionsRow_contextItemContent {
        display: flex;
        align-items: center;
        & > div:first-child {
            flex: 0 0 90%
        }
    }
    .ctalk_ReactionsRow_contextItemTime {
        line-height: $itemHeight;
    }
}
