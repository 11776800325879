/*
Copyright 2020 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

// import font-size variables manually,
// ideally this file would get loaded by the theme which has all variables in context

.mx_ErrorView {
    background: #c5e0f7;
    background: -moz-linear-gradient(top, #c5e0f7 0%, #ffffff 100%);
    background: -webkit-linear-gradient(top, #c5e0f7 0%, #ffffff 100%);
    background: linear-gradient(to bottom, #c5e0f7 0%, #ffffff 100%);
    /* stylelint-disable-next-line function-no-unknown */
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#c5e0f7', endColorstr='#ffffff', GradientType=0);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #000;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0 20px;
    box-sizing: border-box;

    .mx_ErrorView_container {
        max-width: 680px;
        margin: auto;
    }

    .mx_Button {
        border: 0;
        border-radius: 4px;
        font-size: $font-18px;
        margin-left: 4px;
        margin-right: 4px;
        min-width: 80px;
        background-color: var(--cpd-color-text-action-accent);
        color: var(--cpd-color-text-on-solid-primary);
        cursor: pointer;
        padding: 12px 22px;
        word-break: break-word;
        text-decoration: none;
    }

    .mx_Center {
        justify-content: center;
    }

    .mx_HomePage_header {
        color: #2e2f32;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    font-size: $font-16px;
    h1 {
        font-size: $font-32px;
    }
    h2 {
        font-size: $font-24px;
        color: #000;
    }

    .mx_HomePage_col {
        display: flex;
        flex-direction: row;
    }

    .mx_HomePage_row {
        flex: 1 1 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .mx_HomePage_logo {
        margin: auto 20px auto 0;
    }

    h1,
    h2 {
        font-weight: 600;
        margin-bottom: 32px;
    }

    .mx_Spacer {
        margin-top: 24px;
    }

    .mx_FooterLink {
        color: var(--cpd-color-text-link-external);
        text-decoration: none;
    }
}
