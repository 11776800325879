.ctalk_SetupYubiKey {
    &__box {
        max-width: 279px;
        font-size: $font-12px;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 25px;
        min-height: 379px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        &-title {
            text-align: center;
            font-size: $font-20px;
            display: flex;
            align-items: center;
            flex-direction: column;
            &-icon {
                width: 79px;
                height: 79px;
                border: 1px solid #9c9c9c;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                    width: 68px;
                    height: 68px;
                }
            }
            &-userInfoLogin {
                padding: 10px 0;
            }
        }

        &-body {
            .box-body__list{
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                font-size: $font-13px;

                .box-body__item {
                    padding: 10px 0;

                    &-number {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: var(--cpd-color-text-action-accent);
                        color: rgb(255, 255, 255);
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        margin-right: 10px;
                        float: left;
                    }

                    &-detail {
                        max-width: 239px;
                        float: left;
                    }

                    &.icon__connect-yubikey {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: center;
                        position: relative;

                        .yubikey-device {
                            width: 39px;
                            min-height: 39px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 100%;
                            background-image: url('$(web_res)/themes/element/icons/yubikey-disabled.svg');
                            position: absolute;
                            bottom: 39px;
                            left: 10px;
                            &.active {
                                background-image: url('$(web_res)/themes/element/icons/yubikey.svg');
                            }
                        }

                        .laptop {
                            width: 179px;
                            min-height: 179px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 100%;
                            background-image: url('$(web_res)/themes/element/icons/laptop-disabled.svg');
                            &.active {
                                background-image: url('$(web_res)/themes/element/icons/laptop.svg');
                            }
                        }
                    }
                }
            }
        }

        &-footer {
            .input-totp {
                height: 0;
                overflow: hidden;
                border: none;
            }
            .error-message {
                color: var(--cpd-color-text-critical-primary);
                text-align: center;
            }
        }
    }
}
