.ctalk_LinkPanel_Empty {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.ctalk_LinkMessageBody_body {
    padding-top: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ctalk_LinkMessageBody_linkElements {
    padding-top: 5px;
    .ctalk_LinkMessageBody_link {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}

