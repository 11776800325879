.ctalk_UserPreSignedUrl-action {
    margin: 5px 0 20px;
    display: inline-block;
    .mx_AccessibleButton {
        padding-left: 28px;
        position: relative;
    }
    .mx_InlineSpinner {
        position: absolute;
        display: block;
        left: 8px;
        top: 7px;
        .mx_Spinner_icon {
            margin: 0;
        }
    }
    .mx_CopyableText_copyButton{
        &::before {
            content: "";
            display: block;
            position: absolute;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-position: center;
            mask-position: center;
            -webkit-mask-size: contain;
            mask-size: contain;
            left: 8px;
            width: 16px;
            height: 16px;
            top: 8px;
            background: var(--cpd-color-text-action-accent);;
            -webkit-mask-image: url("~matrix-react-sdk/res/img/element-icons/link.svg");
            mask-image: url("~matrix-react-sdk/res/img/element-icons/link.svg")
        }
    }
}

.mx_SpotlightDialog #mx_SpotlightDialog_content .mx_SpotlightDialog_hiddenResults .mx_SpotlightDialog_option {
    padding: 7px 18px 7px 28px;
    line-height: normal;
    color: var(--cpd-color-text-action-accent);;
    margin-top: 10px;

    &[aria-selected="true"] {
        background-color: transparent;
    }

    &:hover {
        background-color: transparent;
    }
}
