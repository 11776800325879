.cpd-theme-light.cpd-theme-light {
  --cpd-color-alpha-pink-1400: hsl(339, 100%, 13%, 1);
  --cpd-color-alpha-pink-1300: hsl(333, 100%, 19%, 1);
  --cpd-color-alpha-pink-1200: hsla(330, 98%, 24%, 0.98);
  --cpd-color-alpha-pink-1100: hsla(331, 100%, 31%, 0.97);
  --cpd-color-alpha-pink-1000: hsla(332, 98%, 36%, 0.97);
  --cpd-color-alpha-pink-900: hsla(333, 98%, 41%, 0.96);
  --cpd-color-alpha-pink-800: hsla(340, 100%, 48%, 0.75);
  --cpd-color-alpha-pink-700: hsla(345, 100%, 50%, 0.47);
  --cpd-color-alpha-pink-600: hsla(346, 100%, 51%, 0.33);
  --cpd-color-alpha-pink-500: hsla(347, 100%, 50%, 0.24);
  --cpd-color-alpha-pink-400: hsla(347, 100%, 50%, 0.13);
  --cpd-color-alpha-pink-300: hsla(347, 100%, 54%, 0.08);
  --cpd-color-alpha-pink-200: hsla(348, 100%, 51%, 0.04);
  --cpd-color-alpha-pink-100: hsla(348, 100%, 51%, 0.02);
  --cpd-color-alpha-fuchsia-1400: hsl(281, 100%, 15%, 1);
  --cpd-color-alpha-fuchsia-1300: hsl(285, 100%, 20%, 1);
  --cpd-color-alpha-fuchsia-1200: hsla(286, 97%, 24%, 0.93);
  --cpd-color-alpha-fuchsia-1100: hsla(289, 96%, 28%, 0.88);
  --cpd-color-alpha-fuchsia-1000: hsla(291, 99%, 30%, 0.84);
  --cpd-color-alpha-fuchsia-900: hsla(293, 100%, 34%, 0.8);
  --cpd-color-alpha-fuchsia-800: hsla(295, 97%, 37%, 0.64);
  --cpd-color-alpha-fuchsia-700: hsla(295, 96%, 37%, 0.43);
  --cpd-color-alpha-fuchsia-600: hsla(297, 93%, 38%, 0.31);
  --cpd-color-alpha-fuchsia-500: hsla(296, 93%, 39%, 0.23);
  --cpd-color-alpha-fuchsia-400: hsla(298, 91%, 40%, 0.13);
  --cpd-color-alpha-fuchsia-300: hsla(295, 89%, 41%, 0.07);
  --cpd-color-alpha-fuchsia-200: hsla(293, 95%, 41%, 0.04);
  --cpd-color-alpha-fuchsia-100: hsla(300, 95%, 41%, 0.02);
  --cpd-color-alpha-purple-1400: hsl(259, 100%, 20%, 1);
  --cpd-color-alpha-purple-1300: hsl(262, 100%, 28%, 1);
  --cpd-color-alpha-purple-1200: hsla(264, 98%, 36%, 0.99);
  --cpd-color-alpha-purple-1100: hsla(260, 97%, 39%, 0.86);
  --cpd-color-alpha-purple-1000: hsla(259, 98%, 42%, 0.79);
  --cpd-color-alpha-purple-900: hsla(258, 98%, 47%, 0.73);
  --cpd-color-alpha-purple-800: hsla(254, 99%, 49%, 0.56);
  --cpd-color-alpha-purple-700: hsla(251, 100%, 51%, 0.38);
  --cpd-color-alpha-purple-600: hsla(249, 100%, 51%, 0.27);
  --cpd-color-alpha-purple-500: hsla(248, 100%, 51%, 0.2);
  --cpd-color-alpha-purple-400: hsla(248, 100%, 53%, 0.12);
  --cpd-color-alpha-purple-300: hsla(248, 100%, 55%, 0.07);
  --cpd-color-alpha-purple-200: hsla(248, 100%, 61%, 0.04);
  --cpd-color-alpha-purple-100: hsla(240, 100%, 61%, 0.02);
  --cpd-color-alpha-blue-1400: hsl(232, 100%, 20%, 1);
  --cpd-color-alpha-blue-1300: hsl(222, 98%, 24%, 1);
  --cpd-color-alpha-blue-1200: hsla(218, 99%, 29%, 0.99);
  --cpd-color-alpha-blue-1100: hsla(216, 99%, 35%, 0.98);
  --cpd-color-alpha-blue-1000: hsla(214, 98%, 39%, 0.99);
  --cpd-color-alpha-blue-900: hsla(213, 99%, 44%, 0.99);
  --cpd-color-alpha-blue-800: hsla(215, 100%, 46%, 0.75);
  --cpd-color-alpha-blue-700: hsla(215, 98%, 47%, 0.51);
  --cpd-color-alpha-blue-600: hsla(216, 95%, 48%, 0.37);
  --cpd-color-alpha-blue-500: hsla(215, 93%, 50%, 0.28);
  --cpd-color-alpha-blue-400: hsla(216, 95%, 51%, 0.16);
  --cpd-color-alpha-blue-300: hsla(215, 100%, 52%, 0.09);
  --cpd-color-alpha-blue-200: hsla(218, 100%, 57%, 0.05);
  --cpd-color-alpha-blue-100: hsla(210, 100%, 61%, 0.03);
  --cpd-color-alpha-cyan-1400: hsl(221, 100%, 16%, 1);
  --cpd-color-alpha-cyan-1300: hsl(213, 100%, 19%, 1);
  --cpd-color-alpha-cyan-1200: hsl(208, 100%, 23%, 1);
  --cpd-color-alpha-cyan-1100: hsl(204, 100%, 28%, 1);
  --cpd-color-alpha-cyan-1000: hsl(202, 100%, 31%, 1);
  --cpd-color-alpha-cyan-900: hsl(200, 100%, 34%, 1);
  --cpd-color-alpha-cyan-800: hsl(194, 100%, 38%, 1);
  --cpd-color-alpha-cyan-700: hsla(186, 99%, 40%, 0.92);
  --cpd-color-alpha-cyan-600: hsla(187, 99%, 38%, 0.54);
  --cpd-color-alpha-cyan-500: hsla(186, 95%, 38%, 0.4);
  --cpd-color-alpha-cyan-400: hsla(186, 100%, 37%, 0.22);
  --cpd-color-alpha-cyan-300: hsla(188, 100%, 38%, 0.11);
  --cpd-color-alpha-cyan-200: hsla(186, 79%, 41%, 0.06);
  --cpd-color-alpha-cyan-100: hsla(180, 79%, 41%, 0.03);
  --cpd-color-alpha-green-1400: hsl(149, 100%, 7%, 1);
  --cpd-color-alpha-green-1300: hsl(157, 100%, 10%, 1);
  --cpd-color-alpha-green-1200: hsl(162, 100%, 14%, 1);
  --cpd-color-alpha-green-1100: hsl(165, 100%, 18%, 1);
  --cpd-color-alpha-green-1000: hsl(166, 100%, 21%, 1);
  --cpd-color-alpha-green-900: hsl(168, 100%, 24%, 1);
  --cpd-color-alpha-green-800: hsl(166, 100%, 30%, 1);
  --cpd-color-alpha-green-700: hsla(163, 99%, 38%, 0.96);
  --cpd-color-alpha-green-600: hsla(156, 99%, 36%, 0.56);
  --cpd-color-alpha-green-500: hsla(154, 96%, 37%, 0.41);
  --cpd-color-alpha-green-400: hsla(151, 93%, 37%, 0.23);
  --cpd-color-alpha-green-300: hsla(150, 100%, 36%, 0.11);
  --cpd-color-alpha-green-200: hsla(150, 79%, 41%, 0.06);
  --cpd-color-alpha-green-100: hsla(156, 79%, 41%, 0.03);
  --cpd-color-alpha-lime-1400: hsl(120, 100%, 7%, 1);
  --cpd-color-alpha-lime-1300: hsl(120, 100%, 11%, 1);
  --cpd-color-alpha-lime-1200: hsl(120, 100%, 15%, 1);
  --cpd-color-alpha-lime-1100: hsl(120, 100%, 19%, 1);
  --cpd-color-alpha-lime-1000: hsl(120, 100%, 22%, 1);
  --cpd-color-alpha-lime-900: hsla(113, 97%, 24%, 0.96);
  --cpd-color-alpha-lime-800: hsla(107, 99%, 29%, 0.91);
  --cpd-color-alpha-lime-700: hsla(102, 100%, 37%, 0.86);
  --cpd-color-alpha-lime-600: hsla(102, 97%, 41%, 0.71);
  --cpd-color-alpha-lime-500: hsla(104, 98%, 40%, 0.51);
  --cpd-color-alpha-lime-400: hsla(105, 92%, 42%, 0.28);
  --cpd-color-alpha-lime-300: hsla(107, 98%, 41%, 0.15);
  --cpd-color-alpha-lime-200: hsla(107, 89%, 44%, 0.07);
  --cpd-color-alpha-lime-100: hsla(103, 75%, 46%, 0.04);
  --cpd-color-alpha-yellow-1400: hsl(6, 100%, 13%, 1);
  --cpd-color-alpha-yellow-1300: hsl(19, 100%, 17%, 1);
  --cpd-color-alpha-yellow-1200: hsl(26, 100%, 21%, 1);
  --cpd-color-alpha-yellow-1100: hsl(30, 100%, 25%, 1);
  --cpd-color-alpha-yellow-1000: hsl(32, 100%, 28%, 1);
  --cpd-color-alpha-yellow-900: hsl(34, 100%, 31%, 1);
  --cpd-color-alpha-yellow-800: hsl(39, 100%, 37%, 1);
  --cpd-color-alpha-yellow-700: hsl(44, 100%, 44%, 1);
  --cpd-color-alpha-yellow-600: hsl(47, 100%, 47%, 1);
  --cpd-color-alpha-yellow-500: hsl(49, 100%, 49%, 1);
  --cpd-color-alpha-yellow-400: hsla(47, 100%, 51%, 0.49);
  --cpd-color-alpha-yellow-300: hsla(47, 100%, 51%, 0.25);
  --cpd-color-alpha-yellow-200: hsla(46, 100%, 53%, 0.13);
  --cpd-color-alpha-yellow-100: hsla(48, 100%, 51%, 0.06);
  --cpd-color-alpha-orange-1400: hsl(0, 100%, 14%, 1);
  --cpd-color-alpha-orange-1300: hsl(0, 100%, 19%, 1);
  --cpd-color-alpha-orange-1200: hsl(0, 100%, 26%, 1);
  --cpd-color-alpha-orange-1100: hsl(13, 100%, 30%, 1);
  --cpd-color-alpha-orange-1000: hsl(18, 100%, 34%, 1);
  --cpd-color-alpha-orange-900: hsl(22, 100%, 37%, 1);
  --cpd-color-alpha-orange-800: hsl(28, 100%, 43%, 1);
  --cpd-color-alpha-orange-700: hsla(27, 100%, 48%, 0.75);
  --cpd-color-alpha-orange-600: hsla(26, 98%, 50%, 0.52);
  --cpd-color-alpha-orange-500: hsla(25, 100%, 50%, 0.37);
  --cpd-color-alpha-orange-400: hsla(25, 100%, 51%, 0.22);
  --cpd-color-alpha-orange-300: hsla(24, 100%, 52%, 0.11);
  --cpd-color-alpha-orange-200: hsla(26, 100%, 55%, 0.07);
  --cpd-color-alpha-orange-100: hsla(22, 100%, 61%, 0.04);
  --cpd-color-alpha-red-1400: hsl(0, 100%, 14%, 1);
  --cpd-color-alpha-red-1300: hsl(0, 100%, 19%, 1);
  --cpd-color-alpha-red-1200: hsl(357, 100%, 26%, 1);
  --cpd-color-alpha-red-1100: hsla(350, 99%, 32%, 0.99);
  --cpd-color-alpha-red-1000: hsla(353, 98%, 37%, 0.95);
  --cpd-color-alpha-red-900: hsla(355, 98%, 41%, 0.91);
  --cpd-color-alpha-red-800: hsla(0, 100%, 51%, 0.77);
  --cpd-color-alpha-red-700: hsla(5, 100%, 51%, 0.5);
  --cpd-color-alpha-red-600: hsla(7, 100%, 51%, 0.36);
  --cpd-color-alpha-red-500: hsla(8, 100%, 51%, 0.27);
  --cpd-color-alpha-red-400: hsla(8, 100%, 52%, 0.15);
  --cpd-color-alpha-red-300: hsla(9, 100%, 54%, 0.08);
  --cpd-color-alpha-red-200: hsla(7, 100%, 56%, 0.04);
  --cpd-color-alpha-red-100: hsla(10, 100%, 61%, 0.03);
  --cpd-color-alpha-gray-1400: hsla(223, 64%, 2%, 0.9);
  --cpd-color-alpha-gray-1300: hsla(225, 57%, 3%, 0.84);
  --cpd-color-alpha-gray-1200: hsla(213, 73%, 3%, 0.77);
  --cpd-color-alpha-gray-1100: hsla(215, 74%, 5%, 0.71);
  --cpd-color-alpha-gray-1000: hsla(218, 79%, 6%, 0.66);
  --cpd-color-alpha-gray-900: hsla(213, 83%, 7%, 0.61);
  --cpd-color-alpha-gray-800: hsla(213, 87%, 9%, 0.5);
  --cpd-color-alpha-gray-700: hsla(215, 96%, 10%, 0.35);
  --cpd-color-alpha-gray-600: hsla(212, 97%, 12%, 0.26);
  --cpd-color-alpha-gray-500: hsla(212, 87%, 15%, 0.2);
  --cpd-color-alpha-gray-400: hsla(213, 90%, 20%, 0.12);
  --cpd-color-alpha-gray-300: hsla(216, 89%, 18%, 0.06);
  --cpd-color-alpha-gray-200: hsla(200, 41%, 36%, 0.04);
  --cpd-color-alpha-gray-100: hsla(210, 48%, 41%, 0.02);
  --cpd-color-pink-1400: #430017;
  --cpd-color-pink-1300: #5f002b;
  --cpd-color-pink-1200: #7e0642;
  --cpd-color-pink-1100: #9f0850;
  --cpd-color-pink-1000: #b80a5b;
  --cpd-color-pink-900: #d20c65;
  --cpd-color-pink-800: #f7407d;
  --cpd-color-pink-700: #ff88a6;
  --cpd-color-pink-600: #ffadc0;
  --cpd-color-pink-500: #ffc2cf;
  --cpd-color-pink-400: #ffdee5;
  --cpd-color-pink-300: #ffecf0;
  --cpd-color-pink-200: #fff5f7;
  --cpd-color-pink-100: #fffafb;
  --cpd-color-fuchsia-1400: #34004c;
  --cpd-color-fuchsia-1300: #4e0068;
  --cpd-color-fuchsia-1200: #671481;
  --cpd-color-fuchsia-1100: #822198;
  --cpd-color-fuchsia-1000: #972aaa;
  --cpd-color-fuchsia-900: #ad33bd;
  --cpd-color-fuchsia-800: #c85ed1;
  --cpd-color-fuchsia-700: #db93e1;
  --cpd-color-fuchsia-600: #e7b2ea;
  --cpd-color-fuchsia-500: #edc6f0;
  --cpd-color-fuchsia-400: #f6dff7;
  --cpd-color-fuchsia-300: #faeefb;
  --cpd-color-fuchsia-200: #fcf5fd;
  --cpd-color-fuchsia-100: #fefafe;
  --cpd-color-purple-1400: #200066;
  --cpd-color-purple-1300: #33008d;
  --cpd-color-purple-1200: #4c05b5;
  --cpd-color-purple-1100: #5d26cd;
  --cpd-color-purple-1000: #6b37de;
  --cpd-color-purple-900: #7a47f1;
  --cpd-color-purple-800: #9271fd;
  --cpd-color-purple-700: #b1a0ff;
  --cpd-color-purple-600: #c5bbff;
  --cpd-color-purple-500: #d4cdff;
  --cpd-color-purple-400: #e6e2ff;
  --cpd-color-purple-300: #f1efff;
  --cpd-color-purple-200: #f8f7ff;
  --cpd-color-purple-100: #fbfbff;
  --cpd-color-blue-1400: #000e65;
  --cpd-color-blue-1300: #012478;
  --cpd-color-blue-1200: #043894;
  --cpd-color-blue-1100: #064ab1;
  --cpd-color-blue-1000: #0558c7;
  --cpd-color-blue-900: #0467dd;
  --cpd-color-blue-800: #4088ee;
  --cpd-color-blue-700: #7eaff6;
  --cpd-color-blue-600: #a3c6fa;
  --cpd-color-blue-500: #bad5fc;
  --cpd-color-blue-400: #d8e7fe;
  --cpd-color-blue-300: #e9f2ff;
  --cpd-color-blue-200: #f4f8ff;
  --cpd-color-blue-100: #f9fcff;
  --cpd-color-cyan-1400: #00194f;
  --cpd-color-cyan-1300: #002b61;
  --cpd-color-cyan-1200: #004077;
  --cpd-color-cyan-1100: #00548c;
  --cpd-color-cyan-1000: #00629c;
  --cpd-color-cyan-900: #0072ac;
  --cpd-color-cyan-800: #0094c0;
  --cpd-color-cyan-700: #15becf;
  --cpd-color-cyan-600: #76d1dd;
  --cpd-color-cyan-500: #9bdde5;
  --cpd-color-cyan-400: #c7ecf0;
  --cpd-color-cyan-300: #e3f5f8;
  --cpd-color-cyan-200: #f1fafb;
  --cpd-color-cyan-100: #f8fdfd;
  --cpd-color-green-1400: #002311;
  --cpd-color-green-1300: #003420;
  --cpd-color-green-1200: #004933;
  --cpd-color-green-1100: #005c45;
  --cpd-color-green-1000: #006b52;
  --cpd-color-green-900: #007a61;
  --cpd-color-green-800: #009b78;
  --cpd-color-green-ctalk: #0dbd8b;
  --cpd-color-green-700: #0bc491;
  --cpd-color-green-600: #71d7ae;
  --cpd-color-green-500: #98e1c1;
  --cpd-color-green-400: #c6eedb;
  --cpd-color-green-300: #e3f7ed;
  --cpd-color-green-200: #f1fbf6;
  --cpd-color-green-100: #f8fdfb;
  --cpd-color-lime-1400: #002400;
  --cpd-color-lime-1300: #003600;
  --cpd-color-lime-1200: #004b00;
  --cpd-color-lime-1100: #005f00;
  --cpd-color-lime-1000: #006e00;
  --cpd-color-lime-900: #197d0c;
  --cpd-color-lime-800: #359d18;
  --cpd-color-lime-700: #54c424;
  --cpd-color-lime-600: #76db4c;
  --cpd-color-lime-500: #99e57e;
  --cpd-color-lime-400: #c8f1ba;
  --cpd-color-lime-300: #e0f8d9;
  --cpd-color-lime-200: #f1fcee;
  --cpd-color-lime-100: #f8fdf6;
  --cpd-color-yellow-1400: #410600;
  --cpd-color-yellow-1300: #541a00;
  --cpd-color-yellow-1200: #692e00;
  --cpd-color-yellow-1100: #803f00;
  --cpd-color-yellow-1000: #8f4d00;
  --cpd-color-yellow-900: #9f5b00;
  --cpd-color-yellow-800: #be7a00;
  --cpd-color-yellow-700: #dea200;
  --cpd-color-yellow-600: #f1bd00;
  --cpd-color-yellow-500: #fbce00;
  --cpd-color-yellow-400: #ffe484;
  --cpd-color-yellow-300: #fff2c1;
  --cpd-color-yellow-200: #fff8e0;
  --cpd-color-yellow-100: #fffcf0;
  --cpd-color-orange-1400: #450000;
  --cpd-color-orange-1300: #620000;
  --cpd-color-orange-1200: #850000;
  --cpd-color-orange-1100: #9b2200;
  --cpd-color-orange-1000: #ac3300;
  --cpd-color-orange-900: #bc4500;
  --cpd-color-orange-800: #dc6700;
  --cpd-color-orange-700: #f89440;
  --cpd-color-orange-600: #fdb37c;
  --cpd-color-orange-500: #ffc8a1;
  --cpd-color-orange-400: #ffdfc8;
  --cpd-color-orange-300: #ffefe4;
  --cpd-color-orange-200: #fff6ef;
  --cpd-color-orange-100: #fffaf7;
  --cpd-color-red-1400: #450000;
  --cpd-color-red-1300: #620000;
  --cpd-color-red-1200: #850006;
  --cpd-color-red-1100: #a4041d;
  --cpd-color-red-1000: #bc0f22;
  --cpd-color-red-900: #d51928;
  --cpd-color-red-800: #ff3d3d;
  --cpd-color-red-700: #ff8c81;
  --cpd-color-red-600: #ffafa5;
  --cpd-color-red-500: #ffc5bc;
  --cpd-color-red-400: #ffdfda;
  --cpd-color-red-300: #ffefec;
  --cpd-color-red-200: #fff7f6;
  --cpd-color-red-100: #fffaf9;
  --cpd-color-gray-1400: #1b1d22;
  --cpd-color-gray-1300: #2b2d32;
  --cpd-color-gray-1200: #3c4045;
  --cpd-color-gray-1100: #4c5158;
  --cpd-color-gray-1000: #595e67;
  --cpd-color-gray-900: #656d77;
  --cpd-color-gray-800: #818a95;
  --cpd-color-gray-700: #a6adb7;
  --cpd-color-gray-600: #bdc4cc;
  --cpd-color-gray-500: #cdd3da;
  --cpd-color-gray-400: #e1e6ec;
  --cpd-color-gray-300: #f0f2f5;
  --cpd-color-gray-200: #f7f9fa;
  --cpd-color-gray-100: #fbfcfd;
  --cpd-color-theme-bg: #ffffff;
  --cpd-color-bg-subtle-secondary-level-0: var(--cpd-color-gray-300);
  --cpd-color-bg-canvas-default-level-1: var(--cpd-color-theme-bg);
  --cpd-color-sticky-date-background: #2B2B2B66;
  --cpd-color-sticky-date-text: #ffffff;
}
