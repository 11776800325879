// Custom matrix-react-sdk styles
.mx_AccessSecretStorageDialog_recoveryKeyEntry {
    flex-direction: column;
}
.mx_AccessSecretStorageDialog {
    .mx_AccessSecretStorageDialog_primaryContainer {
        div.mx_Dialog_buttons {
            flex-direction: row;
            margin-left: 0;
            width: 100%;
        }
    }
}

// CTalk styles
.ctalk_boxRecoverKey {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ctalk_inputRecoverKey {
    width: 80%;
}

.ctalk_typeLoginContainer {
    padding-top: 1em;
    display: flex;
}

.ctalk_appleLoginContainer {
    display: flex;
    padding-top: 1em;
    justify-content: center;
}

.ctalk_resetContainer {
    padding-top: 1em;
}

.ctalk_resetButton {
    color: var(--cpd-color-text-critical-primary);
    font-weight: bold;
}

.ctalk_AccessSecretStorageDialog_recoveryKeyBody {
    width: 100%;
}

.ctalk_CloudKit_login {
    .text-error {
        margin: 20px 0;
    }
}
