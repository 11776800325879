.mx_ReplyTile {
    .mx_ReplyTile_sender {
        .mx_BaseAvatar {
            /*noinspection CssUnresolvedCustomProperty*/
            min-width: var(--cpd-avatar-size);
        }
    }
}

.mx_ReplyChain {
    &.mx_ReplyChain_color1 {
        --user-bg-color: $user-bg-variant1-color;
    }

    &.mx_ReplyChain_color2 {
        --user-bg-color: $user-bg-variant2-color;
    }

    &.mx_ReplyChain_color3 {
        --user-bg-color: $user-bg-variant3-color;
    }

    &.mx_ReplyChain_color4 {
        --user-bg-color: $user-bg-variant4-color;
    }

    &.mx_ReplyChain_color5 {
        --user-bg-color: $user-bg-variant5-color;
    }

    &.mx_ReplyChain_color6 {
        --user-bg-color: $user-bg-variant6-color;
    }

    &.mx_ReplyChain_color7 {
        --user-bg-color: $user-bg-variant7-color;
    }

    &.mx_ReplyChain_color8 {
        --user-bg-color: $user-bg-variant8-color;
    }
}

.mx_ReplyChain_wrapper {
    &:has(.mx_RedactedBody) {
        display: none;
    }
}
