:root, [class*="cpd-theme-"] {
  --cpd-font-letter-spacing-heading-xl: -0.0216em;
  --cpd-font-letter-spacing-heading-lg: -0.0209em;
  --cpd-font-letter-spacing-heading-md: -0.0195em;
  --cpd-font-letter-spacing-heading-sm: -0.0166em;
  --cpd-font-letter-spacing-body-lg: -0.0128em;
  --cpd-font-letter-spacing-body-md: -0.0088em;
  --cpd-font-letter-spacing-body-sm: -0.0031em;
  --cpd-font-letter-spacing-body-xs: 0.0048em;
  --cpd-font-size-root: 1rem;
  --cpd-font-size-heading-xl: 2rem;
  --cpd-font-size-heading-lg: 1.75rem;
  --cpd-font-size-heading-md: 1.5rem;
  --cpd-font-size-heading-sm: 1.25rem;
  --cpd-font-size-body-lg: 1.0625rem;
  --cpd-font-size-body-md: 0.9375rem;
  --cpd-font-size-body-sm: 0.8125rem;
  --cpd-font-size-body-xs: 0.6875rem;
  --cpd-font-line-height-regular: 1.5;
  --cpd-font-line-height-tight: 1.25;
  --cpd-font-line-height-minimum: 1;
  --cpd-font-weight-semibold: 600;
  --cpd-font-weight-medium: 500;
  --cpd-font-weight-regular: 400;
  --cpd-font-family-mono: Inconsolata;
  --cpd-font-family-sans: Inter;
  --cpd-border-width-0-5: 0.5px;
  --cpd-border-width-4: 4px;
  --cpd-border-width-2: 2px;
  --cpd-border-width-1: 1px;
  --cpd-space-56x: 224px;
  --cpd-space-36x: 144px;
  --cpd-space-16x: 64px;
  --cpd-space-12x: 48px;
  --cpd-space-11x: 44px;
  --cpd-space-10x: 40px;
  --cpd-space-6x: 24px;
  --cpd-space-0x: 0px;
  --cpd-space-scale: 4px;
  --cpd-color-icon-on-solid-primary: var(--cpd-color-theme-bg);
  --cpd-color-icon-info-primary: var(--cpd-color-blue-900);
  --cpd-color-icon-success-primary: var(--cpd-color-green-ctalk);
  --cpd-color-icon-critical-primary: var(--cpd-color-red-900);
  --cpd-color-icon-accent-tertiary: var(--cpd-color-green-800);
  --cpd-color-icon-quaternary-alpha: var(--cpd-color-alpha-gray-700);
  --cpd-color-icon-tertiary-alpha: var(--cpd-color-alpha-gray-800);
  --cpd-color-icon-secondary-alpha: var(--cpd-color-alpha-gray-900);
  --cpd-color-icon-primary-alpha: var(--cpd-color-alpha-gray-1400);
  --cpd-color-icon-disabled: var(--cpd-color-gray-700);
  --cpd-color-icon-quaternary: var(--cpd-color-gray-700);
  --cpd-color-icon-tertiary: var(--cpd-color-gray-800);
  --cpd-color-icon-secondary: var(--cpd-color-gray-900);
  --cpd-color-icon-primary: var(--cpd-color-gray-1400);
  --cpd-color-border-info-subtle: var(--cpd-color-blue-500);
  --cpd-color-border-success-subtle: var(--cpd-color-green-500);
  --cpd-color-border-critical-subtle: var(--cpd-color-red-500);
  --cpd-color-border-critical-hovered: var(--cpd-color-red-1000);
  --cpd-color-border-critical-primary: var(--cpd-color-red-900);
  --cpd-color-border-interactive-hovered: var(--cpd-color-gray-1100);
  --cpd-color-border-interactive-secondary: var(--cpd-color-gray-600);
  --cpd-color-border-interactive-primary: var(--cpd-color-gray-800);
  --cpd-color-border-focused: var(--cpd-color-blue-900);
  --cpd-color-border-disabled: var(--cpd-color-gray-500);
  --cpd-color-bg-info-subtle: var(--cpd-color-blue-200);
  --cpd-color-bg-success-subtle: var(--cpd-color-green-200);
  --cpd-color-bg-critical-subtle-hovered: var(--cpd-color-red-300);
  --cpd-color-bg-critical-subtle: var(--cpd-color-red-200);
  --cpd-color-bg-critical-hovered: var(--cpd-color-red-1000);
  --cpd-color-bg-critical-primary: var(--cpd-color-red-900);
  --cpd-color-bg-action-secondary-pressed: var(--cpd-color-alpha-gray-300);
  --cpd-color-bg-action-secondary-hovered: var(--cpd-color-alpha-gray-200);
  --cpd-color-bg-action-secondary-rest: var(--cpd-color-theme-bg);
  --cpd-color-bg-action-primary-disabled: var(--cpd-color-gray-700);
  --cpd-color-bg-action-primary-pressed: var(--cpd-color-gray-1100);
  --cpd-color-bg-action-primary-hovered: var(--cpd-color-gray-1200);
  --cpd-color-bg-action-primary-rest: var(--cpd-color-gray-1400);
  --cpd-color-bg-canvas-disabled: var(--cpd-color-gray-200);
  --cpd-color-bg-canvas-default: var(--cpd-color-theme-bg);
  --cpd-color-bg-subtle-secondary: var(--cpd-color-gray-300);
  --cpd-color-bg-subtle-primary: var(--cpd-color-gray-400);
  --cpd-color-notification-badge-ctalk: var(--cpd-color-green-ctalk);
  --cpd-color-text-on-solid-primary: var(--cpd-color-theme-bg);
  --cpd-color-text-info-primary: var(--cpd-color-blue-900);
  --cpd-color-text-success-primary: var(--cpd-color-green-ctalk);
  --cpd-color-text-critical-primary: var(--cpd-color-red-900);
  --cpd-color-text-link-external: var(--cpd-color-blue-900);
  --cpd-color-text-action-accent: var(--cpd-color-green-ctalk);
  --cpd-color-text-action-primary: var(--cpd-color-gray-1400);
  --cpd-color-text-disabled: var(--cpd-color-gray-800);
  --cpd-color-text-placeholder: var(--cpd-color-gray-800);
  --cpd-color-text-secondary: var(--cpd-color-gray-900);
  --cpd-color-text-primary: var(--cpd-color-gray-1400);
  --cpd-font-heading-xl-semibold: var(--cpd-font-weight-semibold) var(--cpd-font-size-heading-xl)/var(--cpd-font-line-height-tight) var(--cpd-font-family-sans);
  --cpd-font-heading-xl-regular: var(--cpd-font-weight-regular) var(--cpd-font-size-heading-xl)/var(--cpd-font-line-height-tight) var(--cpd-font-family-sans);
  --cpd-font-heading-lg-semibold: var(--cpd-font-weight-semibold) var(--cpd-font-size-heading-lg)/var(--cpd-font-line-height-tight) var(--cpd-font-family-sans);
  --cpd-font-heading-lg-regular: var(--cpd-font-weight-regular) var(--cpd-font-size-heading-lg)/var(--cpd-font-line-height-tight) var(--cpd-font-family-sans);
  --cpd-font-heading-md-semibold: var(--cpd-font-weight-semibold) var(--cpd-font-size-heading-md)/var(--cpd-font-line-height-tight) var(--cpd-font-family-sans);
  --cpd-font-heading-md-regular: var(--cpd-font-weight-regular) var(--cpd-font-size-heading-md)/var(--cpd-font-line-height-tight) var(--cpd-font-family-sans);
  --cpd-font-heading-sm-semibold: var(--cpd-font-weight-semibold) var(--cpd-font-line-height-tight)rem/1.25 var(--cpd-font-family-sans);
  --cpd-font-heading-sm-medium: var(--cpd-font-weight-medium) var(--cpd-font-line-height-tight)rem/1.25 var(--cpd-font-family-sans);
  --cpd-font-heading-sm-regular: var(--cpd-font-weight-regular) var(--cpd-font-line-height-tight)rem/1.25 var(--cpd-font-family-sans);
  --cpd-font-body-lg-semibold: var(--cpd-font-weight-semibold) var(--cpd-font-size-body-lg)/var(--cpd-font-line-height-regular) var(--cpd-font-family-sans);
  --cpd-font-body-lg-medium: var(--cpd-font-weight-medium) var(--cpd-font-size-body-lg)/var(--cpd-font-line-height-regular) var(--cpd-font-family-sans);
  --cpd-font-body-lg-regular: var(--cpd-font-weight-regular) var(--cpd-font-size-body-lg)/var(--cpd-font-line-height-regular) var(--cpd-font-family-sans);
  --cpd-font-body-md-semibold: var(--cpd-font-weight-semibold) var(--cpd-font-size-body-md)/var(--cpd-font-line-height-regular) var(--cpd-font-family-sans);
  --cpd-font-body-md-medium: var(--cpd-font-weight-medium) var(--cpd-font-size-body-md)/var(--cpd-font-line-height-regular) var(--cpd-font-family-sans);
  --cpd-font-body-md-regular: var(--cpd-font-weight-regular) var(--cpd-font-size-body-md)/var(--cpd-font-line-height-regular) var(--cpd-font-family-sans);
  --cpd-font-body-sm-semibold: var(--cpd-font-weight-semibold) var(--cpd-font-size-body-sm)/var(--cpd-font-line-height-regular) var(--cpd-font-family-sans);
  --cpd-font-body-sm-medium: var(--cpd-font-weight-medium) var(--cpd-font-size-body-sm)/var(--cpd-font-line-height-regular) var(--cpd-font-family-sans);
  --cpd-font-body-sm-regular: var(--cpd-font-weight-regular) var(--cpd-font-size-body-sm)/var(--cpd-font-line-height-regular) var(--cpd-font-family-sans);
  --cpd-font-body-xs-semibold: var(--cpd-font-weight-semibold) var(--cpd-font-size-body-xs)/var(--cpd-font-line-height-regular) var(--cpd-font-family-sans);
  --cpd-font-body-xs-medium: var(--cpd-font-weight-medium) var(--cpd-font-size-body-xs)/var(--cpd-font-line-height-regular) var(--cpd-font-family-sans);
  --cpd-font-body-xs-regular: var(--cpd-font-weight-regular) var(--cpd-font-size-body-xs)/var(--cpd-font-line-height-regular) var(--cpd-font-family-sans);
  --cpd-space-64x: 256px;
  --cpd-space-48x: 192px;
  --cpd-space-40x: 160px;
  --cpd-space-32x: 128px;
  --cpd-space-28x: 112px;
  --cpd-space-24x: 96px;
  --cpd-space-20x: 80px;
  --cpd-space-15x: 60px;
  --cpd-space-14x: 56px;
  --cpd-space-13x: 52px;
  --cpd-space-9x: 36px;
  --cpd-space-8x: 32px;
  --cpd-space-7x: 28px;
  --cpd-space-5x: 20px;
  --cpd-space-4x: 16px;
  --cpd-space-3x: 12px;
  --cpd-space-2x: 8px;
  --cpd-space-1-5x: 6px;
  --cpd-space-1x: var(--cpd-space-scale);
  --cpd-space-0-5x: 2px;
}
