.ctalk_Dialog_camera {
    width: 640px;
    overflow-wrap: break-word;
    .ctalk_Dialog_camera_content {
        text-align: center;
        .ctalk_Dialog_camera_actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            &.ctalk_Dialog_camera_actions_capture {
                .ctalk_Dialog_camera_icon_capture {
                    margin-right: 5px;
                }
            }
            &.hidden_switching_camera {
                justify-content: center;
            }
        }
        .ctalk_AccessibleButton {
            &.ctalk_AccessibleButton_Dialog_camera {
                border-radius: 50%;
                background-color: #00000054;
                position: relative;
            }
        }
        .ctalk_AccessibleButton_primary {
            width: 50px;
            height: 50px;
        }
        .ctalk_AccessibleButton_secondary {
            width: 30px;
            height: 30px;
        }
        .ctalk_Dialog_camera_icon {
            fill: white;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            path {
                fill: white;
            }
            &.ctalk_Dialog_camera_icon_back {
                color: white;
                path {
                    fill: none;
                }
            }
        }
        .ctalk_Dialog_camera_space {
            width: 30px;
            height: 30px;
        }
        .ctalk_Dialog_camera_canvas {
            max-width: 640px;
            &.hidden {
                display: none;
                visibility: hidden;
            }
        }
        .ctalk_Dialog_camera_video {
            width: 100%;
            height: auto;
            &.hidden {
                display: none;
                visibility: hidden;
            }
        }
        .errorText {
            color: var(--cpd-color-text-critical-primary);
            font-size: $font-15px;
            margin-top: 15px;
        }
    }
}

.ctalk_Dialog_camara_main {
    .mx_Dialog {
        max-height: 90%;
    }
    .mx_Heading_h3 {
        font-size: 18px;
    }
}
