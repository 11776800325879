.ctalk_MImageReplyBody {
    align-items: center;
    padding-top: 3px;

    .ctalk_MImageBody_thumbnail_Reply {
        max-width: 100px !important;
    }

    .mx_MImageReplyBody_info {
        max-width: calc(100% - 50px);
        min-width: 80px;
        .mx_MImageReplyBody_filename {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
    }

    .ctalk_MImageBody_Background {
        max-width: 80px;
    }

    .ctalk_MVideoBody {
        background-color: unset;
        position: relative;
        padding-top: 3px;
    }

    .mx_MVideoReplyBody {
        border-radius: 8px!important;
        object-fit: cover;
    }

    .mx_MImageBody_thumbnail {
        object-fit: cover;
        height: 44px;
    }

    .mx_MImageBody_gifLabel {
        display: none;
    }

    .ctalk_MImageBody_thumbnail_container_Showing_Image {
        &.mx_MImageBody_thumbnail_container {
            max-width: 44px;
            max-height: 44px;
            min-height: 44px;
        }
    }
    .ctalk_HiddenImagePlaceholder {
        &.mx_MImageBody_thumbnail_container {
            min-height: 44px;
            .mx_HiddenImagePlaceholder {
                min-height: 44px;
            }
        }
    }
}
