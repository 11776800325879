.ck_SessionFolder {
    margin-left: 18px;
    margin-right: 14px;
    margin-bottom: 3px;
    border-bottom: 1px solid var(--cpd-color-alpha-gray-400);
}

.ck_LeftPanel_folder_icon {
    background-color: var(--cpd-color-alpha-gray-300);
    width: var(--height-topLevel);
    min-width: var(--height-topLevel);
    height: var(--height-topLevel);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ck_FolderSettingsButton {
    flex: 0 0 auto;
    border-radius: 8px;
    position: relative;
    margin: 12px auto;
    color: $secondary-content;
    min-width: 32px;
    min-height: 32px;
    line-height: 32px;
    margin-left: calc(var(--gutterSize) + 4px);

    &.expanded {
        margin-left: 20px;
        padding-left: 44px; /* align with toggle collapse button text */
        padding-right: 8px;
    }

    &::before {
        content: "";
        position: absolute;
        width: 32px;
        height: 32px;
        left: 0;
        mask-image: url('$(web_res)/themes/element/icons/settings.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 32px;
        background: $secondary-content;
    }

    &:not(.expanded):hover {
        background-color: $quaternary-content;

        &::before {
            background-color: $primary-content;
        }
    }
}

/* FOLDER SETTINGS */
.ck_FolderSettingsDialog_sessionsIcon::before {
    mask-image: url('$(web_res)/themes/element/icons/chat-folder-icons/opened-folder.svg');
}

.ck_settingTab_ListFolderItems {}

.ck_settingTab_ListFolderItems_helper {
    width: 100%;
    box-sizing: inherit;
    color: var(--cpd-color-text-secondary);
}

.ck_settingTab_FolderItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.ck_FolderItem_fields {
    display: flex;
    align-items: center;
}

.ck_FolderItem_change_index {
    margin-right: 8px;

    .ck_FolderItem_arrow {
        mask-image: url("~matrix-react-sdk/res/img/feather-customised/chevron-down.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        // mask-size: 30px;
        width: 24px;
        height: 14px;
        background: var(--cpd-color-icon-tertiary);
        cursor: pointer;

        /* &.ck_FolderItem_arrow_move_up {
            transform: rotate(180deg);
        } */
        &.up {
            transform: rotate(180deg);
        }

        &.disable {
            cursor: auto;
        }
    }
}

.ck_FolderItem_actions {
    display: flex;
    align-items: center;
}

.ck_FolderItem_icon {
    margin-right: 15px
}

.ck_ChatFolder_icon {
    display: flex;
    fill: var(--cpd-color-icon-tertiary);
    /* path {
        fill: var(--cpd-color-icon-tertiary);
    } */
}

.ck_edit_folder_icon {
    mask-image: url("$(web_res)/themes/element/icons/editpen.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 24px;
    width: 24px;
    height: 24px;
    background: var(--cpd-color-icon-tertiary);
    cursor: pointer;
    margin-right: 15px;
}

.ck_delete_folder_icon {
    mask-image: url("~matrix-react-sdk/res/img/element-icons/trashcan.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 24px;
    width: 24px;
    height: 24px;
    background: var(--cpd-color-icon-critical-primary);
    cursor: pointer;
}

.ck_settingTab_ListFolder_actions {
    display: flex;
    justify-content: space-between;

    .ck_settingTab_ListFolder_create_button {
        margin-bottom: 5px;
    }
}

/* FOLDER DETAILS */
.ck_Folder_details_header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .ck_Folder_details_btn_back {
        margin-right: 15px;
        background-color: var(--cpd-color-bg-subtle-secondary);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        .ck_Folder_details_btn_back_icon {
            mask-image: url("~matrix-react-sdk/res/img/feather-customised/chevron-down.svg");
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 24px;
            width: 24px;
            height: 24px;
            background: var(--cpd-color-icon-tertiary);
            transform: rotate(90deg);
        }
    }
}

.ck_Folder_details {
    .ck_Folder_details_general {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;

        .ck_FolderDetails_field_name {
            width: 100%
        }

        .ck_FolderDetails_icon {
            margin-left: 20px;
            cursor: pointer;
            &:hover {
                background-color: var(--cpd-color-alpha-gray-300);
                border-radius: 8px;
            }
        }
    }

    .ck_Folder_details_rooms {
        margin-bottom: 20px;

        .ck_Folder_details_rooms_field {
            margin-bottom: 10px;
        }

        .ck_Folder_details_rooms_contain {
            max-height: 300px;
            overflow-y: auto;

            .ck_Folder_details_room {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;

                .ck_Folder_details_room_info {
                    display: flex;
                    align-items: center;

                    .mx_DecoratedRoomAvatar {
                        margin-right: 10px;
                    }
                }
                .ck_Folder_details_room_action_delete {
                    mask-image: url("~matrix-react-sdk/res/img/cancel.svg");
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: 18px;
                    width: 18px;
                    height: 18px;
                    background: var(--cpd-color-icon-tertiary);
                    cursor: pointer;
                    padding-right: 15px;
                }
            }
        }
    }

    .ck_Folder_details_actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}

.ck_AddExistingToFolder_footer {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;
}

.ck_SelectFolderIconDialog {
    width: 480px;
    color: $primary-content;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 0;
    height: auto;

    .ck_SelectFolderIconDialog_content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;
        gap: 15px 40px;

        .ck_SelectFolderIcon_item {
            cursor: pointer;
            width: 40px;
            display: flex;
            justify-content: center;

            &:hover {
                background-color: var(--cpd-color-alpha-gray-300);
                border-radius: 8px;
            }
        }
    }
}

.ck_CreateOrEditFolderDialog {
    width: 550px;
    color: $primary-content;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 0;
    height: auto;

    .ck_Folder_details {
        .ck_Folder_details_general {
            .ck_FolderDetails_icon {
                margin-top: 15px;
            }
        }
    }
}

.ck_Folder_settings_invalidText {
    margin-top: 5px;
    color: var(--cpd-color-text-critical-primary);
    font-size: 0.8125rem;
}

.ck_FolderPanel_iconEdit::before {
    mask-image: url('$(web_res)/themes/element/icons/editpen.svg');
}

.ck_FolderPanel_iconRemove::before {
    mask-image: url("~matrix-react-sdk/res/img/element-icons/trashcan.svg");
}