.ctalk_ImageView_description {
    color: white;
    background: black;
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    opacity: 0.7;
}
