.mx_SettingsTab {
    .ctalk_UserName {
        margin-top: 10px;

        &_success {
            color: var(--cpd-color-text-success-primary);
        }

        &_error {
            color: var(--cpd-color-text-critical-primary);
        }

        &_normal {
            color: var(--cpd-color-text-secondary);
        }
    }
    .mx_EmailAddresses_new,
    .mx_EmailAddresses_verify,
    .mx_PhoneNumbers_new,
    .mx_PhoneNumbers_verify {
        margin: 10px 0;
    }

    .ctalk_PhoneNumbers_activeFlowRemove {
        margin-top: 20px;
    }

    .mx_ExistingPhoneNumber_confirmBtn {
        padding-left: 0;
    }

    .ctalk_ThreePID_error {
        color: var(--cpd-color-text-critical-primary);
        margin-bottom: 10px;
    }

    .mx_Field .ctalk_CountryDropdown {
        width: 9rem;
    }
}

.mx_ToggleSwitch.mx_ToggleSwitch_on {
    background-color: var(--cpd-color-green-ctalk);
    > .mx_ToggleSwitch_ball {
        background-color: $call-primary-content;
    }
}
.mx_ToggleSwitch {
    border: unset;
    background-color: $togglesw-off-color;
    .mx_ToggleSwitch_ball {
        background-color: $call-primary-content;
    }
}

.ck_SettingsSubsection_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
