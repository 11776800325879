.mx_Dropdown_AssignUserAdmin {
    margin: 0 0 50px;
}

.mx_Dropdown_menu_AssignUserAdmin {
    max-height: 100px;
}

.mx_Dialog_fixedWidth_AssignUserAdmin {
    max-width: 500px;
}

.mx_Dialog_content_AssignUserAdmin {
    margin-bottom: 25px;
    color: var(--cpd-color-text-critical-primary);
}
