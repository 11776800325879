.mx_UserViewShare {
    max-width: 960px;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.mx_UserViewShare_default {
    text-align: center;
    display: flex;
    .mx_UserViewShare_default_buttons {
        display: flex;
        margin: 30px auto 0;
        width: fit-content;
        .mx_AccessibleButton {
            padding: 15px 12px 15px 45px;
            width: auto;
            margin: 20px;
            position: relative;
            display: inline-block;
            border-radius: 8px;
            vertical-align: top;
            word-break: break-word;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            font-weight: 600;
            font-size: $font-15px;
            line-height: 2.0rem;
            &::before {
                top: 18px;
                left: 12px;
                width: 24px;
                height: 24px;
                content: '';
                position: absolute;
                background-color: #fff;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-size: contain;
                mask-size: contain;
            }
            &.mx_UserViewShare_button_sendDm::before {
                -webkit-mask-image: url("~matrix-react-sdk/res/img/element-icons/feedback.svg");
                mask-image: url("~matrix-react-sdk/res/img/element-icons/feedback.svg");
            }
        }
    }
}


.mx_UserViewShare_default_wrapper {
    margin: auto;
    .mx_UserInfo_profile {
        .mx_UserInfo_profileStatus {
            margin-top: 15px;
        }
    }
}
