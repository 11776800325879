.ctalk_PinnedEventTile {
    &.mx_PinnedEventTile {
        padding-left: 10px;
        width: unset;
        align-items: center;
    }

    &:has(.mx_MImageBody, .mx_MVideoBody, .mx_MFileBody, .mx_MVoiceMessageBody) {
        .mx_ReplyChain_wrapper {
            padding-bottom: 10px;
        }
    }

    .mx_PinnedEventTile_footer {
        .ck_Group_MessageTimestamp {
            color: var(--cpd-color-text-secondary);
            font-size: .625rem;
            display: inline-flex;
            align-items: center;
            gap: 5px;
        }
    }
}

.ctalk_PinnedEventTile_box {
    grid-area: name;
    display: flex;
    overflow: hidden;
}

.mx_RightPanel_ResizeWrapper {
    .mx_PinnedEventTile_message {
        .ctalk_MessageEvent_forward {
            padding: 10px 0;
        }

        .ctalk_MVideoBody_description {
            min-width: 320px;
            max-width: 320px;
        }

        .mx_MImageReplyBody_info {
            max-width: calc(100% - 80px);
        }
    }
}

.ctalk_PinnedEventTile_sender {
    font-weight: 600;
    font-size: $font-15px;
    line-height: 2.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 75%;
}

.ctalk_PinnedEventTile_order_text {
    position: relative;
    width: 25%;
}

.ctalk_PinnedEventTile_order {
    grid-area: order;
    font-weight: 600;
    font-size: $font-15px;
    line-height: 2.4rem;
    color: var(--cpd-color-text-link-external);
    position: absolute;
    right: 0;
}

.mx_PinnedEventTile_message {
    .mx_MImageBody {
        .ctalk_MImageBody_Preview {
            text-align: -webkit-center;
            text-align: -moz-center;
        }
    }

    // Disable click to event reply into pinned

    .mx_ReplyTile {
        pointer-events: none;
    }
}
