.mx_ReplyTile {
    .mx_MFileBody {
        user-select: none;
        pointer-events: none;
    }
}

.mx_PinnedEventTile_message,
.mx_EventTile,
.mx_ReplyPreview {
    .mx_ReplyTile {
        .mx_MFileBody {
            .ctalk_MFileBody_description {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.mx_MFileBody .mx_MediaBody {
    color: inherit;
    max-width: 100%;
}

.mx_MFileBody .mx_MediaBody {
    color: inherit;
    max-width: 100%;
    .mx_MFileBody_info .mx_MFileBody_info_icon {
        &:has(.ctalk_MFileBody_Placeholder_button) {
            &::before {
                display: none;
            }
        }
    }
}

.mx_PinnedEventTile_message,
.mx_EventTile {
    &:has(.mx_ReplyChain_wrapper) {
        .mx_MFileBody .mx_MediaBody {
            padding-right: 5px;
            padding-bottom: 10px;
        }
    }

    .mx_ReplyChain {
        .mx_MFileBody .mx_MediaBody {
            background-color: unset;
            margin: 0;
            padding-right: 12px;
            padding-bottom: unset;
        }
        .mx_ReplyTile {
            .mx_MFileBody_info.mx_MediaBody {
                padding: 0;
                display: flex;
                align-items: center;
            }
            .mx_MFileBody {
                &:has(.ctalk_MFileBody_description) {
                    .mx_MFileBody_info .mx_MFileBody_info_filename {
                        color: var(--cpd-color-text-link-external);
                        padding-right: 5px;
                        &::after {
                            content: ',';
                        }
                    }
                }
                .mx_MFileBody_info {
                    .mx_MFileBody_info_filename {
                        color: var(--cpd-color-text-link-external);
                    }
                    .mx_MFileBody_info_icon {
                        display: none;
                    }
                    .ctalk_MFileBody_description {
                        padding-bottom: unset;
                    }
                }
            }
        }
    }

    .mx_MFileBody .mx_MediaBody {
        background-color: var(--backgroundColor);
    }


    .mx_MFileBody_info .mx_MFileBody_info_filename {
        width: unset;
    }

    .mx_ReplyTile {
        .mx_MFileBody_info.mx_MediaBody {
            margin: 0;
            padding-right: 12px;
        }
    }
}

.ctalk_MFileBody_description {
    cursor: initial;
    color: inherit;
    white-space: pre-wrap;
    margin: 5px 0;
    padding-bottom: 10px;
}

.ck_MFileBody_description_preview_reply_error {
    color: var(--cpd-color-text-primary);
}

.ctalk_MFileBody_Placeholder {
    position: relative;
    .ctalk_MFileBody_Placeholder_button {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        &_download {
            position: absolute;
            background-color: hsla(215, 96%, 10%, 0.35);
            border-radius: 50%;
            cursor: pointer;
            &::after {
                content: "";
                display: block;
                width: 32px;
                height: 32px;
                background-color: #ffffff;
                mask-size: 14px;
                mask-repeat: no-repeat;
                mask-image: url('~matrix-react-sdk/res/img/download.svg');
                mask-position: center;
                opacity: 90%;
            }
        }
    }
}
