// Specified for modern mode only
.mx_RoomView_body[data-layout="group"] {
    .ctalk_MessageSelection_layer {
        left: -1px;
        right: 10px;

        &.ctalk_MessageSelection_selected {
            background-color: rgba(128, 128, 128, 0.15);
            border-radius: 8px;
        }
    }

    .ctalk_MessageSelection_checkbox {
        right: 0;
    }
}

.mx_RoomView_body[data-layout="bubble"] {
    .mx_RoomView_timeline,
    .mx_RoomView_statusArea,
    .mx_MessageComposer {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        &:has(.ck_Spinner_scroll_to_token) {
            max-width: unset;
        }
    }
}

.ctalk_RoomSelection_container {
    display: flex;
    align-items: center;
    width: calc(100% - 100px);
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px 50px;

    & > div {
        width: 50%;
    }

    .mx_AccessibleButton {
        text-transform: uppercase;
        font-weight: 600;
        padding-left: 30px;
        &:first-child {
            margin-right: 20px;
        }
    }
}

.ctalk_RoomSelection_containerCount {
    display: inline-flex;
}
.ctalk_RoomSelection_containerLeft {
    padding-top: 5px;
    display: flex;
    align-items: center;
    .mx_AccessibleButton {
        margin: 0;
    }
}

.ctalk_RoomSelection_containerRight {
    text-align: right;
    padding-right: 25px;
}

/*noinspection CssUnresolvedCustomProperty*/
.ctalk_MessageSelection_layer {
    z-index: 10;
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: calc(-1 * var(--EventTile_bubble-margin-inline-start));
    right: calc(-1 * var(--EventTile_bubble-margin-inline-end));
    user-select: none;

    &.ctalk_MessageSelection_canSelect {
        user-select: auto;
        cursor: pointer;
    }
}

.ctalk_MessageSelection_checkbox {
    $whiteColor: #fff;
    $checkBoxSize: 20px;
    $checkedBoxSize: 20px;
    $checkIconSize: 14px;

    position: absolute;
    right: -67px;
    top: 50%;
    transform: translateY(-50%);

    &.mx_StyledRadioButton {
        input[type="radio"] {
            & + div {
                width: $checkBoxSize;
                height: $checkBoxSize;
            }
           &:checked + div > div {
               min-width: $checkedBoxSize;
               max-width: $checkedBoxSize;
               height: $checkedBoxSize;
               border: solid 1px $whiteColor;
               border-radius: 50%;
               &::after {
                   content: "";
                   mask-image: url("~matrix-react-sdk/res/img/compound/checkbox-32px.svg");
                   display: block;
                   width: $checkIconSize;
                   height: $checkIconSize;
                   background-color: $whiteColor;
                   color: $whiteColor;
                   mask-size: $checkIconSize;
                   mask-position: 2px 2px;
               }
           }
       }
    }
}

.mx_MessageContextMenu_iconSelect {
    &::before {
        /*noinspection CssUnknownTarget*/
        mask-image: url('$(web_res)/themes/element/icons/check-circle.svg');
    }
}

.ctalk_AccessibleButton_icon {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 16px;
        height: 16px;
        left: -20px;
        top: -8px;
        mask-position: center;
        mask-size: contain;
        background-color: white;
    }
}
.ctalk_AccessibleButton_iconDelete {
    &::before {
        mask-image: url('~matrix-react-sdk/res/img/element-icons/trashcan.svg');
    }
}

.ctalk_AccessibleButton_iconForward {
    &::before {
        mask-image: url('~matrix-react-sdk/res/img/element-icons/message/fwd.svg');
    }
}

// Fix width when responsive
.ctalk_MPollEndBody_wrapper {
    width: 100%;
}

// Fix: hide line in context menu with ReactionsMenu
.mx_IconizedContextMenu .mx_IconizedContextMenu_optionList:nth-child(2)::before {
    border: none;
    content: none;
}

// Fix background height same as avatar size
.mx_IncomingLegacyCallToast {
    &.mx_Toast_body {
        .mx_BaseAvatar,
        .mx_BaseAvatar_initial {
            /*noinspection CssUnresolvedCustomProperty*/
            height: var(--cpd-avatar-size);
        }
    }
}

.ctalk_ViewCommonRooms_container {
    margin-left: 8px;
    padding-right: 7px;
    .ctalk_ViewCommonRooms_notfound {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.mx_RightPanel {
    .mx_MemberList_invite {
        width: calc(100% - var(--cpd-space-4x) - 32px);
    }
}
.ctalk_RoomGeneralContextMenu_iconAutoDelete {
    &::before {
        /*noinspection CssUnknownTarget*/
        mask-image: url('$(web_res)/themes/element/icons/auto-delete.svg');
    }
}
.ctalk_AutoDeleteMessage_heading {
    display: flex;
    & > div {
        flex-basis: 50%;
    }
    .mx_ToggleSwitch {
        margin-top: 5px;
    }
}
.ctalk_AutoDeleteMessage_roomMarker {
    &:after {
        content: '';
        width: 30px;
        height: 30px;
        border: solid 2px var(--cpd-color-alpha-gray-300);
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        margin: 3px;
        opacity: 75%;
        z-index: -1;
        outline: dashed 2px var(--cpd-color-icon-tertiary);
    }
    &.ck_AutoDeleteMessage_roomMarker_telegram_style {
        &:after {
            width: 48px;
            height: 48px;
            margin: 9px 7px;
        }
    }
}

.mx_RoomSublist_minimized {
    .ctalk_AutoDeleteMessage_roomMarker {
        &:after {
            left: calc(50% - 20px);
        }
        &.ck_AutoDeleteMessage_roomMarker_telegram_style {
            &:after {
                left: calc(50% - 30px);
                margin: 5px 4px;
            }
        }
    }
}
.ctalk_AutoDeleteMessage_label {
    background: transparent;
    font-weight: bold;
    color: var(--cpd-color-icon-tertiary);
    border: dashed 2px var(--cpd-color-icon-tertiary);
    border-radius: 50%;
    padding: 4px 3px;
    margin-right: 4px;
    &.hover:hover {
        background-color: var(--cpd-color-green-300);
        border-color: var(--cpd-color-text-action-accent);
        color: var(--cpd-color-text-action-accent);
    }

    &.click {
        cursor: pointer;
    }

    .ctalk_AutoDeleteMessage_label_size_3 {
        font-size: 0.8em
    }
}

.ctalk_AutoDeleteMsg_OptionCustom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    margin-top: 5px;
}

.ctalk_AutoDeleteMsg_RadioButon {
    width: 25px;
    flex-grow: 0;
}

.ctalk_MaxLifetimeCustom_unit {
    margin-left: 5px;
}

.ck_Reject_Upload {
    margin: 24px 0;
}

// Handle height when composing a message, the user composes multiple lines
.mx_SendMessageComposer .mx_BasicMessageComposer .mx_BasicMessageComposer_input {
    max-height: 50vh;
}

.ck_Spinner_scroll_to_token {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: var(--cpd-color-bg-canvas-default);
}

.ck_MessageComposer_wrapper_custom {
    padding-left: 20px;
    border-top: 1px solid var(--cpd-color-alpha-gray-400);
}

.ck_MessageComposer_row {
    min-height: 55px;
    vertical-align: middle;
    margin: auto;
    border-top: 1px solid transparent;
    display: flex;
    gap: 10px;
}

.mx_SendWysiwygComposer .mx_WysiwygComposer_Editor {
    padding: 0;
}
